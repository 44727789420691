import moment from "moment";
import React, { useState } from "react";
import CustomInputField from "../customInputField";
import CustomInputFieldForWork from "../customInputFieldForWork";
import { sectionGridCX } from "./styledClass";

const WorkInformation = ({ uniqueEmployee }) => {
  const {
    dynamicId,
    workCategory,
    agentName,
    policeClearance,
    policeClearanceNo,
    policeClearanceIsDate,
    paymentAmount,
    dueAmount,
    levels,
  } = uniqueEmployee;
  const [clearance, setClearance] = useState(
    policeClearance?.split(",")[0] ? policeClearance?.split(",")[0] : "No"
  );

  const handleClearance = (event) => {
    setClearance(event.target.value.split(",")[0]);
  };

  return (
    <div>
      <div className={sectionGridCX}>
        <CustomInputField
          label={"Profession"}
          type={"text"}
          name={"profession"}
          defaultValue={uniqueEmployee?.profession}
          uppercase={true}
        />

        <CustomInputField
          label={"Country"}
          type={"text"}
          name={"country"}
          defaultValue={uniqueEmployee?.country}
          uppercase={true}
        />

        <CustomInputField
          label={"Date of Birth"}
          type={"date"}
          name={"birthDate"}
          defaultValue={uniqueEmployee?.birthDate}
          uppercase={true}
        />

        <CustomInputField
          label={"Religion"}
          type={"text"}
          name={"religion"}
          defaultValue={uniqueEmployee?.religion}
          uppercase={true}
        />

        <CustomInputField
          label={"Issue Place"}
          type={"text"}
          name={"issuePlace"}
          defaultValue={uniqueEmployee?.issuePlace}
          uppercase={true}
        />

        <CustomInputField
          label={"Gender"}
          type={"text"}
          name={"gender"}
          defaultValue={uniqueEmployee?.gender}
          select={true}
          options={[
            ["Male", "Male"],
            ["Female", "Female"],
            ["Transgender", "Transgender"],
            ["Prefer not to respond", "Prefer not to respond"],
          ]}
          uppercase={true}
        />

        <CustomInputField
          label={"Height (CM)"}
          type={"text"}
          name={"height"}
          defaultValue={uniqueEmployee?.height}
          uppercase={true}
        />

        <CustomInputField
          label={"Weight (KG)"}
          type={"text"}
          name={"weight"}
          defaultValue={uniqueEmployee?.weight}
          uppercase={true}
        />
      </div>
    </div>
  );
};

export default WorkInformation;
