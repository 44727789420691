import React, { useState } from "react";
import CustomAlert from "../customeAlert";
import UpdateUser from "../updateUserForm";

import { tableBodyStyle } from "./styledClass";

import TableElementAdmin from "./TableElementAdmin";
import TableHeadAdmin from "./TableHeadAdmin";

const TableAdmin = ({ users, updateUserId, handleUpdateUserId }) => {
  const [deleteID, setDeleteID] = useState("");

  let uniqueUser = "";

  if (updateUserId) {
    uniqueUser = users.filter((item) => item.id === updateUserId);
  }

  const handleDeleteID = (event) => {
    setDeleteID(event.target.id);
  };
  return (
    <table className={tableBodyStyle}>
      <TableHeadAdmin />
      <tbody>
        {users.map((item, index) => (
          <TableElementAdmin
            key={index}
            serial={index + 1}
            item={item}
            even={index % 2 === 1}
            handleDeleteID={handleDeleteID}
            handleUpdateUserId={handleUpdateUserId}
          />
        ))}
      </tbody>
      {deleteID && <CustomAlert endPoint={`user/list`} deleteID={deleteID} />}
      {uniqueUser && <UpdateUser uniqueUser={uniqueUser} />}
    </table>
  );
};

export default TableAdmin;
