import React from "react";
import "./style.css";
import { loadingBodyCX, loadingCX } from "./styledClass";

const Loading = () => {
  const isDarkMode = localStorage.getItem("darkMode") === "true";

  if (isDarkMode) {
    document.documentElement.classList.remove("light");
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
    document.documentElement.classList.add("light");
  }
  return (
    <div className="flex justify-center items-center h-[100px] dark:bg-[#292929]">
      <div className={`${loadingCX} sm`}></div>
    </div>
  );
};

export default Loading;
