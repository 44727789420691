import React from "react";
import CustomInputField from "../customInputField";
import { sectionGridCX } from "./styledClass";

const WorkInformation = ({ clearance, setClearance }) => {
  const handleClearance = (event) => {
    setClearance(event.target.value.split(",")[0]);
  };
  return (
    <div>
      <div className={sectionGridCX}>
        {/* <CustomInputField
          label={"Work Category"}
          type={"text"}
          name={"workCategory"}
          select={true}
          options={[
            ["CARP", "Carpenter"],
            ["CH", "Chef"],
            ["CE", "Civil Engineer"],
            ["EE", "Electrical Engineer"],
            ["ELEC", "Electrician"],
            ["GW", "General Worker"],
            ["GR", "Grinder"],
            ["HRC", "Horeca"],
            ["HVAC", "HVAC Technician"],
            ["MAS", "Mason"],
            ["MAN", "Manager"],
            ["ME", "Mechanical Engineer"],
            ["MW", "Millwright"],
            ["PF", "Pipe Fitter"],
            ["SF", "Steel Fixer"],
            ["WP", "Welder-Plate"],
            ["WS", "Welder-SMAW"],
            ["WT", "Welder-TIG"],
          ]}
          required={true}
        /> */}
        <CustomInputField
          label={"Profession"}
          type={"text"}
          name={"profession"}
          uppercase={true}
        />

        <CustomInputField
          label={"Country"}
          type={"text"}
          name={"country"}
          uppercase={true}
        />

        <CustomInputField
          label={"Date of Birth"}
          type={"date"}
          name={"birthDate"}
          uppercase={true}
        />

        <CustomInputField
          label={"Religion"}
          type={"text"}
          name={"religion"}
          uppercase={true}
        />

        <CustomInputField
          label={"Issue Place"}
          type={"text"}
          name={"issuePlace"}
          uppercase={true}
        />

        <CustomInputField
          label={"Gender"}
          type={"text"}
          name={"gender"}
          select={true}
          options={[
            ["Male", "Male"],
            ["Female", "Female"],
            ["Transgender", "Transgender"],
            ["Prefer not to respond", "Prefer not to respond"],
          ]}
          uppercase={true}
        />

        <CustomInputField
          label={"Height (CM)"}
          type={"text"}
          name={"height"}
          uppercase={true}
        />

        <CustomInputField
          label={"Weight (KG)"}
          type={"text"}
          name={"weight"}
          uppercase={true}
        />
      </div>
    </div>
  );
};

export default WorkInformation;
