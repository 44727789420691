import React from "react";
import logo from "../../../assets/logo.png";
import logoColor from "../../../assets/logoColor.png";
import {
  footerBody,
  footerBox,
  footerContainer,
  footerImage,
} from "./styledClass";

const Footer = ({ darkTheme }) => {
  return (
    <footer className={footerContainer}>
      <div className={footerBox}>
        <div className={footerBody}>
          {/*   <img
            src={darkTheme ? logo : logoColor}
            alt="logo"
            className={`${footerImage} h-12 md:h-24 w-24 md:w-auto`}
          /> */}
          <p>&copy; {new Date().getFullYear()} ADMCBD LTD</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
