import moment from "moment";
import React from "react";

const ProfessionalInformation = ({ data }) => {
  return (
    <div className="font-bold  grid grid-cols-3">
      <div className="grid grid-cols-2 col-span-2 ">
        <div className="pl-2 border-black dark:border-gray-400 border">
          PROFESSION
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.profession}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          COUNTRY
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.country}
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border">
          {" "}
          BIRTH DATE
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.birthDate}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          RELIGION
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.religion}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          ISSUE PLACE
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.issuePlace}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          GENDER
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.gender}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          HEIGHT (CM)
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.height}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          WEIGHT (KG)
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.weight}
        </div>
      </div>
      <div className="pl-2 border-black dark:border-gray-400 border mx-2">
        <img className="max-h-32 m-auto" src={data?.photo} alt="User Img" />
      </div>
    </div>
  );
};

export default ProfessionalInformation;
