import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

function CustomEditor({ oldData, saveDataHandler, closeEditMode }) {
  const editorRef = useRef(null);
  const sendDataHandler = () => {
    if (editorRef.current) {
      saveDataHandler(editorRef.current.getContent());
    }
  };

  async function fileUploader(file) {
    const body = new FormData();
    // backdrop
    const backDropDiv = document.querySelector(".tox .tox-dialog");
    backDropDiv.classList.add("loading");
    // disable input
    const textInputArea = document.querySelector(
      ".tox-form__controls-h-stack .tox-control-wrap input.tox-textfield"
    );
    textInputArea.disabled = true;

    body.append("image", file);
    const url = `https://api.imgbb.com/1/upload?key=347525e64775187aed4d03329dbe793a`;
    const data = await fetch(url, {
      method: "POST",
      body: body,
    })
      .then((res) => res.json())
      .then((res) => {
        // return data
        return res.data.url;
      })
      .catch((err) => {
        console.log(err);
      });
    backDropDiv.classList.remove("loading");
    textInputArea.disabled = false;
    return data;
  }

  return (
    <div>
      <Editor
        apiKey="vwoipfm090lxii0f30df144djin8pzfodjappwdgkq315l9x"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={oldData}
        // toolbar="bold italic underline code formatselect image"
        plugins="lists code image wordcount link hr table"
        init={{
          height: 500,
          menubar: false,
          toolbar:
            "undo redo styles | formatselect | " +
            "bold italic hr table link forecolor backcolor image | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat",
          file_picker_types: "image",
          file_picker_callback: (cb, value, meta) => {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");

            input.addEventListener("change", (e) => {
              const file = e.target.files[0];

              const reader = new FileReader();
              reader.addEventListener("load", async () => {
                const uploadedUrl = await fileUploader(file);
                cb(uploadedUrl, { title: file.name });
              });
              reader.readAsDataURL(file);
            });

            input.click();
          },
          // plugins: [
          //   "advlist autolink lists link image charmap print preview anchor",
          //   "searchreplace visualblocks code fullscreen",
          //   "insertdatetime media table paste code help wordcount",
          // ],
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      <div className="w-full flex items-center justify-end gap-4 pt-4">
        {/* Cancel button */}
        <div className="">
          <button
            onClick={closeEditMode}
            className="btn btn-outline dark:text-white dark:hover:bg-white dark:hover:text-[#2C2E33]"
            style={{
              padding: "0px 45px",
            }}
          >
            Cancel
          </button>
        </div>
        {/* Submit button */}
        <div className="">
          <button
            onClick={sendDataHandler}
            className="btn dark:text-white dark:hover:bg-white dark:hover:text-[#2C2E33]"
            style={{
              padding: "0px 45px",
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default CustomEditor;

// CK editor codes
// function uploadAdapter(loader) {
//   return {
//     upload: () => {
//       return new Promise((resolve, reject) => {
//         const body = new FormData();
//         loader.file.then((file) => {
//           // console.log(file);
//           body.append("image", file);
//           const url = `https://api.imgbb.com/1/upload?key=347525e64775187aed4d03329dbe793a`;
//           fetch(url, {
//             method: "POST",
//             body: body,
//           })
//             .then((res) => res.json())
//             .then((res) => {
//               resolve({ default: res.data.url });
//             })
//             .catch((err) => {
//               reject(err);
//             });
//         });
//       });
//     },
//   };
// }

// function uploadPlugin(editor) {
//   editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
//     return new uploadAdapter(loader);
//   };
// }
{
  /* <CKEditor
        config={{
          extraPlugins: [uploadPlugin],
        }}
        editor={ClassicEditor}
        data={oldData}
        onReady={(editor) => {
          const data = editor.getData();
          setTextData(data);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setTextData(data);
        }}
        // onBlur={(event, editor) => {
        //   console.log("Blur.", editor);
        // }}
        // onFocus={(event, editor) => {
        //   console.log("Focus.", editor);
        // }}
      /> */
}
