import { QRCodeSVG } from "qrcode.react";
import React from "react";

const MedicalDetail = ({ data }) => {
  return (
    <div className="font-semibold">
      <div className="grid grid-cols-2 ">
        <div className="pl-2 border-black dark:border-gray-400 border col-span-2 text-center">
          MEDICAL EXAMINATION
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          TYPE OF EXAMINATION
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          RESULTS
        </div>
        <div className="pl-2 border-black  dark:border-gray-400 border row-span-2 flex items-center">
          {" "}
          <div className="my-auto">EYE Visual Acuity</div>
        </div>
        <div className=" border-black dark:border-gray-400 border row-span-2 grid grid-cols-3">
          <div className="pl-2 border-black border-t-0 border-l-0 dark:border-gray-400 border">
            R:
          </div>
          <div className="pl-2 border-black border-t-0  dark:border-gray-400 border col-span-2">
            {data?.rightEyeVisualAcuity}
          </div>
          <div className="pl-2 border-black border-b-0 border-l-0 dark:border-gray-400 border">
            L:
          </div>
          <div className="pl-2 border-black  border-b-0 dark:border-gray-400 border col-span-2">
            {data?.leftEyeVisualAcuity}
          </div>
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border row-span-2 flex items-center">
          {" "}
          <div className="my-auto">EAR</div>
        </div>
        <div className=" border-black dark:border-gray-400 border row-span-2 grid grid-cols-3">
          <div className="pl-2 border-black  border-l-0  border-t-0 dark:border-gray-400 border">
            R:
          </div>
          <div className="pl-2 border-black   border-t-0  dark:border-gray-400 border col-span-2">
            {data?.rightEar}
          </div>
          <div className="pl-2 border-black border-l-0  border-b-0  dark:border-gray-400 border">
            L:
          </div>
          <div className="pl-2  border-b-0  border-black dark:border-gray-400 border col-span-2">
            {data?.leftEar}
          </div>
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border col-span-2 text-center">
          SYSTEMIC EXAM: CARDIO-VASCULAR
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border">
          {" "}
          B.P
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.bp}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          Heart
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.heart}
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border col-span-2 text-center">
          RESPIRATORY EXAM
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border">
          LUNGS
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.lungs}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          GASTROINTESTINAL ABDOMEN
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.gastrointestinalAbdomen}
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border col-span-2 text-center">
          OTHERS
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border">
          HERNIA
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.hernia}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          VARICOSEVEINS
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.varicoseveins}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          DEFORMITIES
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.deformities}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          SKIN
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.skin}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          C.N.S
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.cns}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          EXTREMITIES
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.extremities}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          PSYCHIATRY
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.psychiatry}
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border col-span-2 text-center">
          VENEREAL DISEASE
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border">
          SYMPTOMS
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.symptoms}
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border col-span-2 text-center">
          X-RAY INVESTIGATION
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border">
          {" "}
          CHEST X-RAY
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.chestXRay}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">ECG</div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.ecg}
        </div>
      </div>

      <div className="flex  mt-[1px]">
        <div className="w-1/2 flex border border-black">
          <div className="m-auto">
            <span className="mx-auto">
              <QRCodeSVG
                width={100}
                value={window.location.origin + "/" + data?._id}
              />
            </span>
          </div>
          <div
            style={{ writingMode: "vertical-rl", textOrientation: "mixed" }}
            className="w-[10%] text-sm "
          >
            <div className="w-full border-l py-2 border-black">
              SCAN FOR ONLINE INFO
            </div>
          </div>
        </div>

        <div className="w-1/2 flex border border-black ml-1">
          <div
            style={{ writingMode: "vertical-rl", textOrientation: "mixed" }}
            className="w-[10%] text-sm "
          >
            <div className="w-full border-r py-2 text-center border-black">
              X-RAY IMAGE
            </div>
          </div>

          <div className="m-auto w-[85%] h-[100%]">
            <span className="mx-auto ">
              <img
                className=" w-[100%] h-[100%] "
                src={data?.xrayImg}
                alt="x-ray img"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalDetail;
