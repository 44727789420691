import React from "react";
import HeadInformation from "./headInformation";
import PersonalDetail from "./personalDetail";
import ProfessionalInformation from "./ProfessionalInformation";
import LaboratoryDetails from "./LaboratoryDetails";
import MedicalDetail from "./MedicalDetail";
import signatureImage from "../../assets/signature.jpg";

const PrintFullpage = ({ logo, data, print }) => {
  return (
    <div className="flex items-center justify-center mt-[-8px]">
      <div className="dark:bg-[#292929] text-black  dark:text-white page">
        <HeadInformation logo={logo} data={data} />

        <div className="grid grid-cols-2 gap-5 pr-2  pl-2">
          <PersonalDetail data={data} />
          <ProfessionalInformation data={data} />
        </div>

        <div className="grid grid-cols-2 gap-5 px-2">
          <MedicalDetail data={data} />
          <LaboratoryDetails data={data} />
        </div>

        <div className="font-bold pr-2 pl-2 text-xs">
          <div className="flex justify-between ">
            <h3 className="py-1">
              His / Her Status{" "}
              <span className="border bg-yellow-300 px-5 border-black dark:border-gray-400 font-bold uppercase text-base ">
                {data?.status}
              </span>
            </h3>
          </div>
          <div className="flex justify-between">
            <h3 className="py-2">
              REMARKS: <span className="ml-5 ">{data?.remarks}</span>
            </h3>

            <div className="lg:-ml-50 mt-5">
              <div>
                <img
                  className=" w-[90%] max-h-16 object-fill -mt-10 ml-4"
                  src={signatureImage}
                  alt="signature"
                />
              </div>
              <div>
                <h3 className="my-5 -mt-3 -ml-20">
                  Final Checked By:............................................
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintFullpage;
