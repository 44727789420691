import React, { useEffect, useState, useRef } from "react";
import Footer from "../../component/shared/footer";
import NavBar from "../../component/shared/navbar";
import Fullpage from "./Fullpage";
import Loading from "../../component/shared/loading/Loading";
import useFetch from "../../hooks/useFetch";
import useSession from "../../hooks/useSession";
import { useParams } from "react-router-dom";
import logo from "../../assets/logo.png";
import HTMLToPDF from "../createPDF/PrintComponent";

const IndividualPerson = () => {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true"
  );
  const [print, setPrint] = useState(false);
  const { id } = useParams();
  const [data, , employeeLoading] = useFetch("form", id);
  const [user, userLoading] = useSession();

  console.log(data);

  if (employeeLoading || userLoading) {
    return <Loading />;
  }

  return (
    <main>
      <NavBar
        user={user ? user : "client"}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
      />

      <Fullpage logo={logo} data={data} />

      <Footer darkTheme={darkMode} />
    </main>
  );
};

export default IndividualPerson;
