import moment from "moment";
import { toast } from "react-toastify";
import admin from "./../assets/admin.png";
import moderator from "./../assets/moderator.png";
import client from "./../assets/customer.png";
// export const RoutePath = {

// };

// export const apiPath = {

// };

export const getToken = () => {
  const tempToken = sessionStorage.getItem("ebc202c5c66c906f41dfee622fb64221")
    ? sessionStorage.getItem("ebc202c5c66c906f41dfee622fb64221")
    : "asdf";

  let charPlace = tempToken.indexOf(".");
  let payLoad = tempToken.slice(0, charPlace + 1);
  let legitToken = tempToken.slice(charPlace + 11);
  return payLoad + legitToken;
};

export const StatusUpdate = (passportExpDate, policeClearanceIsDate) => {
  const currentDate = moment();
  const passportExpireDate = moment(passportExpDate);

  if (policeClearanceIsDate !== false) {
    if (Math.abs(currentDate.diff(policeClearanceIsDate, "month")) <= 4) {
      return "success";
    } else if (
      Math.abs(currentDate.diff(policeClearanceIsDate, "month")) >= 6
    ) {
      return "expired";
    } else if (
      Math.abs(currentDate.diff(policeClearanceIsDate, "month")) <= 5
    ) {
      return "warning";
    }
  } else if (passportExpDate === null) {
    return "notFound";
  } else if (passportExpDate !== false) {
    if (passportExpireDate.diff(currentDate, "month") >= 12) {
      return "success";
    } else if (passportExpireDate.diff(currentDate, "month") < 6) {
      return "expired";
    } else if (passportExpireDate.diff(currentDate, "month") >= 6) {
      return "warning";
    }
  } else {
    return "expired";
  }

  /*//function for both condition match at the same time
   if (policeClearanceIsDate) {
    if (
      passportExpireDate.diff(currentDate, "month") >= 12 &&
      Math.abs(currentDate.diff(policeClearanceIsDate, "month")) <= 4
    ) {
      return "success";
    } else if (
      passportExpireDate.diff(currentDate, "month") < 6 ||
      Math.abs(currentDate.diff(policeClearanceIsDate, "month")) >= 6
    ) {
      return "expired";
    } else if (
      passportExpireDate.diff(currentDate, "month") >= 6 ||
      Math.abs(currentDate.diff(policeClearanceIsDate, "month")) <= 5
    ) {
      return "warning";
    }
  } else {
    return "expired";
  } */
};

export const userRoleImage = (role) => {
  let photo;
  if (role === "admin") {
    photo = admin;
  } else if (role === "moderator") {
    photo = moderator;
  } else if (role === "viewer") {
    photo = client;
  } else if (role === "client") {
    photo = client;
  }
  return photo;
};
