import React from "react";
import parser from "html-react-parser";
import "./DocStructure.css";
import { useState } from "react";
import CustomEditor from "./CustomEditor";
import axios from "axios";
import { toast } from "react-toastify";

function DocStructure({ data, user }) {
  const [editMode, setEditMode] = useState(false);
  const [allData, setAllData] = useState(data);
  const { role } = user;

  function editModeToggler() {
    setEditMode((old) => !old);
  }

  function closeEditMode() {
    setEditMode(false);
  }

  async function saveDataHandler(params) {
    setAllData(params);
    setEditMode(false);
    const data = {
      docData: params,
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/user/doc`, data)
      .then((response) => {
        toast.success("Data saved successfully!", {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        toast.error("Something went wrong!", {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    <div className="w-full flex items-center justify-center">
      <div
        className="w-full dark:bg-[#292929]/50 dark:text-gray-100"
        style={{
          minHeight: editMode ? "100%" : "78vh",
        }}
      >
        {/* outer container with Gray BG */}
        <div
          className="cus-container dark:text-gray-100"
          style={{
            padding: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* Container with fixed width */}
          <div
            style={{
              width: "100%",
              maxWidth: "1520px",
              //   padding: "0 2rem",
            }}
            className="my-container"
          >
            {/* top container */}
            <div className="flex justify-between items-center">
              <div
                className="w-full flex flex-wrap gap-4 justify-between items-center"
                style={{
                  padding: "50px 0",
                }}
              >
                <h2
                  className="font-semibold text-2xl lg:text-3xl uppercase"
                  // style={{
                  //   color: "#444",
                  // }}
                >
                  Documentation
                </h2>
                {/* Edit button */}
                {(role === "admin" || role === "moderator") && !editMode && (
                  <div>
                    <button
                      onClick={editModeToggler}
                      className="btn btn-outline dark:text-white dark:hover:bg-white dark:hover:text-[#2C2E33]"
                      style={{
                        padding: "0px 25px",
                      }}
                    >
                      Edit
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* parsed data */}
          </div>
        </div>
        {/* Bottom container with content/editor */}
        <div
          style={{
            padding: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* width fix here */}
          <div
            style={{
              width: "100%",
              maxWidth: "1570px",
              padding: "0 2rem",
              margin: "50px 0",
            }}
          >
            <div>
              {!editMode ? (
                allData?.length > 0 && (
                  <div className="parser">{parser(allData)}</div>
                )
              ) : (
                <CustomEditor
                  oldData={allData || ""}
                  saveDataHandler={saveDataHandler}
                  closeEditMode={closeEditMode}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocStructure;
