import React, { useState } from "react";
import AddButton from "../../component/addButton";
import Footer from "../../component/shared/footer";

import Loading from "../../component/shared/loading/Loading";
import NavBar from "../../component/shared/navbar";
import useFetch from "../../hooks/useFetch";
import useSession from "../../hooks/useSession";
import AdminPanelStructure from "./AdminPanelStructure";
import { pageContainer } from "./styledClass";

const AdminPanel = () => {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true"
  );

  const [users, , usersLoading] = useFetch("user/list");
  const [user, userLoading] = useSession();

  if (usersLoading || userLoading) {
    return <Loading />;
  }

  return (
    <main className={pageContainer}>
      <NavBar user={user} darkMode={darkMode} setDarkMode={setDarkMode} />
      <AdminPanelStructure users={users} />
      <Footer darkTheme={darkMode} />
    </main>
  );
};

export default AdminPanel;
