import { QRCodeSVG } from "qrcode.react";
import React from "react";

const MedicalDetail = ({ data }) => {
  return (
    <div className="font-normal mt-1">
      <div className=" grid grid-cols-2 text-[12px]">
        <div className="pl-1 border-black dark:border-gray-400 border col-span-2 text-center font-bold text-sm">
          MEDICAL EXAMINATION
        </div>
        <div className="pl-1  text-center border-black dark:border-gray-400 border-x font-bold">
          TYPE OF EXAMINATION
        </div>
        <div className="pl-1 border-black dark:border-gray-400  border-r font-bold text-center">
          RESULTS
        </div>
        <div className="pl-1 border-black  dark:border-gray-400 border-x border-t row-span-2 flex items-center ">
          {" "}
          <div className="my-auto mx-auto font-bold">EYE VISUAL ACUITY</div>
        </div>
        <div className=" border-black dark:border-gray-400 border-t border-r row-span-2 grid grid-cols-3 ">
          <div className="pl-1 border-black  dark:border-gray-400  text-center w-full font-bold">
            R:
          </div>
          <div className="pl-1 border-black  dark:border-gray-400 border-l col-span-2 text-center w-full font-bold">
            {data?.rightEyeVisualAcuity}
          </div>
          <div className="pl-1 border-black  dark:border-gray-400  text-center w-full border-t font-bold">
            L:
          </div>
          <div className="pl-1 border-black  dark:border-gray-400 border-l border-t col-span-2 text-center w-full font-bold">
            {data?.leftEyeVisualAcuity}
          </div>
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border-t border-x row-span-2 flex items-center text-center w-full">
          {" "}
          <div className="my-auto mx-auto font-bold">EAR</div>
        </div>
        <div className="border-r border-t border-black dark:border-gray-400  row-span-2 grid grid-cols-3 text-center w-full">
          <div className="pl-1 border-black dark:border-gray-400  text-center w-full font-bold">
            R:
          </div>
          <div className="pl-1 border-black  dark:border-gray-400 border-l col-span-2 text-center w-full font-bold">
            {data?.rightEar}
          </div>
          <div className="pl-1 border-black  border-t dark:border-gray-400 font-bold">
            L:
          </div>
          <div className="pl-1 border-l border-t border-black dark:border-gray-400  col-span-2 font-bold">
            {data?.leftEar}
          </div>
        </div>

        <div className="pl-1 border-black dark:border-gray-400 border col-span-2 text-center font-bold">
          SYSTEMIC EXAM: CARDIO-VASCULAR
        </div>

        <div className="pl-1  border-black dark:border-gray-400 border-b border-l">
          {" "}
          B.P
        </div>
        <div className="pl-1 font-normal border-black dark:border-gray-400 border-b border-x">
          {data?.bp} mm/hg
        </div>
        <div className="pl-1 font-normal border-black dark:border-gray-400 border-b border-l ">
          Heart
        </div>
        <div className="pl-1 font-normal border-black dark:border-gray-400 border-b border-x text-center">
          {data?.heart}
        </div>

        <div className="pl-1 border-black dark:border-gray-400 border-t-0 border col-span-2 text-center font-bold">
          RESPIRATORY EXAM
        </div>

        <div className="pl-1 font-normal border-black dark:border-gray-400 border-b border-l">
          LUNGS
        </div>
        <div className="pl-1 font-normal border-black dark:border-gray-400 border-b border-x text-center">
          {data?.lungs}
        </div>
        <div className="pl-1 font-semibold text-[12px] border-black dark:border-gray-400 border-b border-l">
          GASTROINTESTINAL ABDOMEN
        </div>
        <div className="pl-1 font-normal border-black dark:border-gray-400 border-b border-x text-center">
          {data?.gastrointestinalAbdomen}
        </div>

        <div className="pl-1 border-black dark:border-gray-400 border col-span-2 text-center font-bold border-t-0">
          OTHERS
        </div>

        <div className="pl-1 border-black dark:border-gray-400 border-b border-l">
          HERNIA
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border-b border-x text-center">
          {data?.hernia}
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border-b border-l">
          VARICOSEVEINS
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border-b border-x text-center">
          {data?.varicoseveins}
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border-b border-l">
          DEFORMITIES
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border-b border-x text-center">
          {data?.deformities}
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border-b border-l">
          SKIN
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border-b border-x text-center">
          {data?.skin}
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border-b border-l">
          C.N.S
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border-b border-x text-center">
          {data?.cns}
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border-b border-l">
          EXTREMITIES
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border-b border-x text-center">
          {data?.extremities}
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border-b border-l">
          PSYCHIATRY
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border-b border-x text-center">
          {data?.psychiatry}
        </div>

        <div className="pl-1 border-black dark:border-gray-400 font-bold border-x col-span-2 text-center">
          VENEREAL DISEASE
        </div>

        <div className="pl-1 border-black dark:border-gray-400 border-t border-l">
          SYMPTOMS
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border border-b-0 text-center">
          {data?.symptoms}
        </div>

        <div className="pl-1 border-black dark:border-gray-400 border border-b-0 col-span-2 text-center font-bold">
          X-RAY INVESTIGATION
        </div>

        <div className="pl-1 border-black dark:border-gray-400 border-t border-x font-bold text-center">
          CHEST X-RAY
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border-t border-r font-bold text-center">
          {data?.chestXRay}
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border font-bold text-center">
          ECG
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border-y border-r font-bold text-center">
          {data?.ecg}
        </div>
      </div>

      <div className="flex  mt-[1px]">
        <div className="w-1/2 flex border border-black">
          <div className="m-auto">
            <span className="mx-auto">
              <QRCodeSVG
                width={100}
                value={window.location.origin + "/print/" + data?._id}
              />
            </span>
          </div>
          <div
            style={{ writingMode: "vertical-rl", textOrientation: "mixed" }}
            className="w-[10%] text-sm "
          >
            <div className="w-full border-l py-2 border-black">
              SCAN FOR ONLINE INFO
            </div>
          </div>
        </div>

        <div className="w-1/2 flex border border-black ml-1">
          <div
            style={{ writingMode: "vertical-rl", textOrientation: "mixed" }}
            className="w-[10%] text-sm "
          >
            <div className="w-full border-r py-2 text-center border-black">
              X-RAY IMAGE
            </div>
          </div>

          <div className="m-auto w-[85%] h-[180px]">
            <span className="mx-auto ">
              <img
                className=" w-[100%] h-[100%] "
                src={data?.xrayImg}
                alt="x-ray img"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalDetail;
