import React, { useEffect, useState } from "react";
import AddButton from "../../component/addButton";
import AddUpdateEmployee from "../../component/formModal";
import SearchBar from "../../component/searchBar";
import "../../component/tableComponent/style.css";
import Table from "../../component/tableComponent/Table";
import LoadingSmall from "../../component/shared/loading/LoadingSmall";

import {
  addButtonContainer,
  homeContainer,
  tableContainer,
} from "./styledClass";
import FilterOption from "../../component/filterOption";
import FilterModal from "../../component/filterModal";

const HomePageStructure = ({
  employee,
  user,
  searchBarActive,
  handleSearch,
  loading,
  filter,
  setFilter,
}) => {
  //console.log(loading, "loading");
  const { role, status } = user;
  const [updateEmployeeID, setUpdateEmployee] = useState("");

  const handleUpdateEmployee = (event) => {
    setUpdateEmployee(event.target.id);
  };

  return (
    <div className={homeContainer}>
      {/* <SearchBar
        searchBarActive={searchBarActive}
        handleSearch={handleSearch}
        status={status}
      /> */}
      <div className="flex justify-end p-1">
        {!(role === "client" || role === "viewer") ? (
          status === "active" ? (
            <div className={addButtonContainer}>
              <AddButton trigger="addEmployee" />
            </div>
          ) : (
            <div
              className={`${addButtonContainer} opacity-25 cursor-not-allowed`}
            >
              <AddButton />
            </div>
          )
        ) : (
          <></>
        )}
        {/* <div className="flex justify-end py-5">
          <FilterOption
            filter={filter}
            setFilter={setFilter}
            trigger="filterEmployee"
          />
        </div> */}
      </div>
      {role === "client" ? (
        <div className={tableContainer}>
          <Table
            employee={employee}
            handleUpdateEmployee={handleUpdateEmployee}
            updateEmployeeID={updateEmployeeID}
            adminRole={role}
            userStatus={status}
          />
          {/* Loader */}
          {loading && (
            <div className="relative w-full py-4">
              <div className="flex w-full items-center justify-center">
                <LoadingSmall />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={tableContainer}>
          {searchBarActive || filter.length ? (
            <div className=" p-3">
              Total Data Found:{" "}
              <span className="font-bold">{employee?.length}</span>
            </div>
          ) : (
            <></>
          )}

          <Table
            employee={employee}
            handleUpdateEmployee={handleUpdateEmployee}
            updateEmployeeID={updateEmployeeID}
            adminRole={role}
            userStatus={status}
          />
          {/* Loader */}
          {loading && (
            <div className="relative w-full py-4">
              <div className="flex w-full items-center justify-center">
                <LoadingSmall />
              </div>
            </div>
          )}
        </div>
      )}
      {(role === "admin" || role === "moderator") && <AddUpdateEmployee />}
      {(role === "admin" || role === "moderator") && (
        <FilterModal filter={filter} setFilter={setFilter} />
      )}
    </div>
  );
};

export default HomePageStructure;
