import { Route, Routes } from "react-router-dom";

import AdminAuth from "./auth/AdminAuth";
import RequireAuth from "./auth/RequireAuth";
import UserAuth from "./auth/UserAuth";

import HomePage from "./page/homePage";
import AdminPanel from "./page/adminPanel";
import Login from "./component/login/Login";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IndividualPerson from "./page/individualPerson";
import IndividualPersonPrint from "./page/PrintIndividualPerson";
import DocPage from "./page/documentation";
import Statistics from "./page/statistics";

function App() {
  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });

  return (
    <div>
      <Routes>
        <Route
          index
          element={
            <RequireAuth>
              <HomePage />
            </RequireAuth>
          }
        />

        <Route path="/:id" element={<IndividualPerson />} />
        <Route path="print/:id" element={<IndividualPersonPrint />} />
        <Route
          path="/documentation"
          element={
            <RequireAuth>
              <DocPage />
            </RequireAuth>
          }
        />
        <Route
          path="/admin"
          element={
            <AdminAuth>
              <AdminPanel />
            </AdminAuth>
          }
        />
        <Route
          path="/stats"
          element={
            <RequireAuth>
              <Statistics />
            </RequireAuth>
          }
        />
        <Route
          path="/login"
          element={
            <UserAuth>
              <Login />
            </UserAuth>
          }
        />
      </Routes>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
