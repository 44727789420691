import moment from "moment";
import React from "react";
import Barcode from "react-barcode";

const HeadInformation = ({ logo, data }) => {
  return (
    <>
      {/*   <div className="container mx-auto pt-6 px-4 lg:px-10 text-center md:flex items-center justify-center">
        <div className="md:mr-5">
          <img className="max-h-20 m-auto" src={logo} alt="logo" />
        </div>
        <div>
          <h1 className="font-Jacques text-3xl font-bold text-pink-500">
            ADHUNIK DIAGNOSTIC & MEDICAL CENTRE LTD
          </h1>
          <h4 className="uppercase font-semibold text-xl">
            81/3, D.I.T ext road (2nd floor) (zinat tower goli), motijheel
            dhaka, Bangladesh
          </h4>
          <h4 className="capitalize font-semibold text-xl">
            mobile: +88 01715 881 868, Email:{" "}
            <span className="lowercase">adhunikdiagnostic252@gmail.com</span>
          </h4>
          <h3 className="uppercase font-bold text-xl">
            online report:{" "}
            <span className="lowercase font-semibold">
              {window.location.origin}
            </span>
          </h3>
        </div>
      </div>
      <div className="text-center my-5">
        <h2 className="text-2xl font-bold underline m-auto">
          MEDICAL TEST REPORT
        </h2>
      </div>
      <div className="px-5 inline-block md:flex justify-between">
        <p className="flex ">
          <span className="font-bold">Reg No.:</span>{" "}
          <span className="flex flex-col">
            <Barcode
              height={20}
              textPosition="top"
              textAlign="left"
              margin={0}
              background="none"
              value={data?.regNo ? data.regNo : "AMC2310431"}
            />
          </span>
        </p>
        <div>
          <p>
            <span className="font-bold">Reg Date:</span> {data?.regDate}{" "}
            {data?.regTime}
          </p>
          <p>
            <span className="font-bold">Report Date:</span> {data?.reportDate}
          </p>
        </div>
      </div> */}
      <div className=" mx-auto pt-4 px-4 lg:px-10 text-center flex items-center justify-center">
        <div className="text-right">
          <img className="max-h-20 m-auto" src={logo} alt="logo" />
        </div>
        <div>
          <h1 className="font-Jacques text-xl font-bold text-pink-500">
            ADHUNIK DIAGNOSTIC & MEDICAL CENTRE LTD
          </h1>
          <h4 className="uppercase font-semibold text-sm">
            81/3, D.I.T ext road (2nd floor) (zinat tower goli), motijheel
            dhaka, Bangladesh
          </h4>
          <h4 className="capitalize font-semibold text-base">
            mobile: +88 01715 881 868, Email:{" "}
            <span className="lowercase">adhunikdiagnostic252@gmail.com</span>
          </h4>
          <h3 className="uppercase font-bold text-base">
            online report:{" "}
            <span className="lowercase ">{window.location.origin}</span>
      
          </h3>
        </div>
      </div>
      <div className="text-center mt-1">
        <h2 className="text-lg font-bold underline my-5 md:m-auto">
          MEDICAL TEST REPORT
        </h2>
      </div>
      <div className="px-5 block md:flex ">
        <p className="flex w-[52%]">
          <span className="font-bold">Reg No.:</span>{" "}
          <span className="ml-5 flex flex-col ">
            <Barcode
              font="arial"
              fontOptions="bold"
              fontSize={15}
              height={20}
              textPosition="top"
              textAlign="left"
              margin={0}
              background="none"
              value={data?.regNo ? data.regNo : "AMC2310431"}
            />
          </span>
        </p>
        <div className="font-bold text-xs  my-1">
          <p>
            REG DATE{" "}
            <span className="ml-7">
              {moment(data?.regDate).format("DD-MM-YYYY")} {data?.regTime}
            </span>
          </p>
          <p>
            REPORT DATE{" "}
            <span className="ml-2">
              {moment(data?.reportDate).format("DD-MM-YYYY")}
            </span>{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default HeadInformation;
