import React, { useRef } from "react";
import { updateData } from "../../hooks/update";
import CustomInputField from "../customInputField";

import {
  sectionGridCX,
  formBodyCX,
  formTitleCX,
  modalBoxCX,
  modalCloseButtonCX,
  modalCX,
  modalToggleCX,
  submitButtonCX,
  submitButton,
} from "./styledClass";

const UpdateUser = ({ uniqueUser }) => {
  const { id, name, email, role, status } = uniqueUser[0];

  const formRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {};

    if (event.target.password.value) {
      data = {
        name: event.target.name?.value,
        email: event.target.email?.value,
        password: event.target.password?.value,
        role: event.target.role?.value.split(",")[0],
        status: event.target.status?.value.split(",")[0],
      };
    } else {
      data = {
        name: event.target.name?.value,
        email: event.target.email?.value,
        role: event.target.role?.value.split(",")[0],
        status: event.target.status?.value.split(",")[0],
      };
    }

    updateData(`user/list/${id}`, data, false);
    updateData({
      endPoint: `user/list/${id}`,
      data: data,
      method: "PUT",
      modalCloseID: "updateUser",
    });
    //formRef.current.reset();
  };
  return (
    <div>
      <input type="checkbox" id="updateUser" className={modalToggleCX} />
      <div className={modalCX}>
        <div className={modalBoxCX}>
          <label
            htmlFor="updateUser"
            className={modalCloseButtonCX}
            onClick={() => document.getElementById("updateUser-form").reset()}
          >
            ✕
          </label>
          <form
            id="updateUser-form"
            ref={formRef}
            onSubmit={handleSubmit}
            className={formBodyCX}
          >
            <h2 className={formTitleCX}>Update User</h2>

            <div className={sectionGridCX}>
              <CustomInputField
                label={"User Full Name"}
                type={"text"}
                name={"name"}
                defaultValue={name}
                required={true}
              />

              <CustomInputField
                label={"Username"}
                type={"text"}
                name={"email"}
                defaultValue={email.split("@")[0]}
                required={true}
              />

              <CustomInputField
                label={"User Password"}
                type={"password"}
                name={"password"}
              />

              <CustomInputField
                label={"User Role"}
                type={"text"}
                name={"role"}
                select={true}
                options={[
                  ["admin", "Admin"],
                  ["moderator", "Moderator"],
                  ["viewer", "Viewer"],
                  ["client", "Client"],
                ]}
                defaultValue={role}
              />
              <CustomInputField
                label={"User Status"}
                type={"text"}
                name={"status"}
                select={true}
                options={[
                  ["active", "Active"],
                  ["inactive", "Inactive"],
                ]}
                defaultValue={status}
              />
            </div>

            <div className={submitButtonCX}>
              <button type="submit" className={submitButton}>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateUser;
