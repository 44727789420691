import React from "react";

const PersonalDetail = ({ data }) => {
  console.log(data);
  return (
    <div className="font-bold ">
      <div className="grid grid-cols-3 ">
        <div className="pl-2  border-black dark:border-gray-400 border">
          NAME
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border col-span-2">
          {data?.name}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          PASSPORT NO
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.passportNo}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border m-2 row-span-6">
          <img
            className="pl-2 border-black dark:border-gray-400 max-h-30 m-auto"
            src={data?.fingerPrint}
            alt="Finger print"
          />
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          MERITAL STATUS
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.maritalStatus}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">AGE</div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.age}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          ISSUE DATE
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.issueDate}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          NATIONALITY
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.nationality}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          VISA NO.
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.visaNo}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          AGENCY
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border col-span-2">
          {data?.agency}
        </div>
      </div>
    </div>
  );
};

export default PersonalDetail;
