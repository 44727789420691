import React from "react";
import {
  cellImageBodyStyle,
  imageDivStyle,
  imageStyle,
  nameStyle,
  locationStyle,
  workCategoryStyle,
  statusDivStyle,
  actionDivStyle,
  viewButtonStyle,
  editButtonStyle,
  deleteButtonStyle,
  statusStyle,
  tableCellStyle,
} from "./styledClass";
import { StatusUpdate } from "../../utility/Constant";
import { Link } from "react-router-dom";

const TableElement = ({
  serial,
  item,
  even,
  handleDeleteID,
  handleUpdateEmployee,
  adminRole,
  userStatus,
}) => {
  const {
    _id,
    photo,
    name,
    country,
    age,
    agency,
    city,
    status,
    dynamicId,
    levels,
    passportExpDate,
    policeClearance,
    policeClearanceIsDate,
    regNo,
  } = item;
  const policeStatus = StatusUpdate(false, policeClearanceIsDate);
  const passportStatus = StatusUpdate(passportExpDate, false);

  return (
    <tr className={tableCellStyle(even)}>
      <th>{serial >= 10 ? serial : "0" + serial}</th>
      <td>
        <div className={cellImageBodyStyle}>
          <div className={imageDivStyle}>
            <div className={imageStyle}>
              <img
                src={
                  photo
                    ? photo
                    : item?.gender === "male"
                    ? "https://i.ibb.co/c8bxd3G/male.webp"
                    : item?.gender === "female"
                    ? "https://i.ibb.co/z2wyPT2/female.webp"
                    : "https://i.ibb.co/LQBXSsr/Placeholder.png"
                }
                alt={name + " photo"}
              />
            </div>
          </div>

          <div className="uppercase">
            <div className={nameStyle}>{name}</div>
            <div className={locationStyle}>
              {country}, {city}
            </div>
          </div>
        </div>
      </td>
      <td>{regNo}</td>
      <td className="uppercase">
        {status}
        {/* <br />
        <span className={workCategoryStyle}>{dynamicId}</span>{" "}
        {levels ? (
          <span className={workCategoryStyle}>
            {levels.replace("Level", "LV")}
          </span>
        ) : (
          <></>
        )} */}
      </td>

      <td>{age}</td>
      <td>{agency}</td>
      {/* <td>
        <div className={statusDivStyle}>
          <div
            className={
              policeClearance === "No"
                ? statusStyle("notFound")
                : statusStyle(policeStatus)
            }
          />
        </div>
      </td> */}
      {/* <td>
        <div className={statusDivStyle}>
          <div className={statusStyle(passportStatus)} />
        </div>
      </td> */}
      <th>
        <div className={actionDivStyle}>
          {/*  {userStatus === "active" ? (
            <Link to={_id} className={viewButtonStyle}>
              <i className="fa-solid fa-eye" />
            </Link>
          ) : (
            <button
              className={viewButtonStyle + " opacity-25 cursor-not-allowed"}
            >
              <i className="fa-solid fa-eye" />
            </button>
          )} */}

          {userStatus === "active" ? (
            <Link to={`print/${_id}`} className={viewButtonStyle}>
              <i class="fa-solid fa-print"></i>
            </Link>
          ) : (
            <button
              className={viewButtonStyle + " opacity-25 cursor-not-allowed"}
            >
              <i class="fa-solid fa-print"></i>
            </button>
          )}

          {adminRole === "admin" || adminRole === "moderator" ? (
            userStatus === "active" ? (
              <label
                htmlFor="updateEmployee"
                onClick={handleUpdateEmployee}
                className={editButtonStyle}
              >
                <i id={_id} className="fa-solid fa-pen" />
              </label>
            ) : (
              <label
                onClick={handleUpdateEmployee}
                className={editButtonStyle + " opacity-25 cursor-not-allowed"}
              >
                <i id={_id} className="fa-solid fa-pen" />
              </label>
            )
          ) : (
            ""
          )}

          {adminRole === "admin" ? (
            userStatus === "active" ? (
              <label
                htmlFor={"delete-modal"}
                onClick={() => {
                  handleDeleteID(_id, name);
                }}
                className={deleteButtonStyle}
                disabled
              >
                <i id={_id} className="fa-solid fa-trash" />
              </label>
            ) : (
              <label
                onClick={handleDeleteID}
                className={deleteButtonStyle + " opacity-25 cursor-not-allowed"}
                disabled
              >
                <i id={_id} className="fa-solid fa-trash" />
              </label>
            )
          ) : (
            ""
          )}
        </div>
      </th>
    </tr>
  );
};

export default TableElement;
