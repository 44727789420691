import React from "react";
import CustomInputField from "../customInputField";
import { sectionGridCX } from "./styledClass";

const MedicalInformation = () => {
  return (
    <div>
      <div className={sectionGridCX}>
        {/* <CustomInputField
          label={"Status"}
          type={"text"}
          name={"passportStat"}
          select={true}
          options={[
            ["Not Submitted", "Not Submitted"],
            ["Submitted", "Submitted"],
            ["Returned", "Returned"],
          ]}
        /> */}

        <CustomInputField
          label={"Left Eye Visual Acuity"}
          type={"text"}
          name={"leftEyeVisualAcuity"}
          uppercase={true}
          defaultValue={"6/6"}
        />
        <CustomInputField
          label={"Right Eye Visual Acuity"}
          type={"text"}
          name={"rightEyeVisualAcuity"}
          uppercase={true}
          defaultValue={"6/6"}
        />
        <CustomInputField
          label={"Left Ear"}
          type={"text"}
          name={"leftEar"}
          uppercase={true}
          defaultValue={"NAD"}
        />

        <CustomInputField
          label={"Right Ear"}
          type={"text"}
          name={"rightEar"}
          uppercase={true}
          defaultValue={"NAD"}
        />

        <CustomInputField
          label={"B.P"}
          type={"text"}
          name={"bp"}
          uppercase={true}
        />

        <CustomInputField
          label={"Heart"}
          type={"text"}
          name={"heart"}
          uppercase={true}
          defaultValue={"NAD"}
        />

        <CustomInputField
          label={"Lungs"}
          type={"text"}
          name={"lungs"}
          uppercase={true}
          defaultValue={"NAD"}
        />

        <CustomInputField
          label={"Gastrointestinal Abdomen"}
          type={"text"}
          name={"gastrointestinalAbdomen"}
          uppercase={true}
          defaultValue={"NAD"}
        />

        <CustomInputField
          label={"Hernia"}
          type={"text"}
          name={"hernia"}
          uppercase={true}
          defaultValue={"ABSENT"}
        />

        <CustomInputField
          label={"Varicoseveins"}
          type={"text"}
          name={"varicoseveins"}
          uppercase={true}
          defaultValue={"ABSENT"}
        />

        <CustomInputField
          label={"Deformities"}
          type={"text"}
          name={"deformities"}
          uppercase={true}
          defaultValue={"ABSENT"}
        />

        <CustomInputField
          label={"Skin"}
          type={"text"}
          name={"skin"}
          uppercase={true}
          defaultValue={"NAD"}
        />

        <CustomInputField
          label={"Cns"}
          type={"text"}
          name={"cns"}
          uppercase={true}
          defaultValue={"NAD"}
        />

        <CustomInputField
          label={"Extremities"}
          type={"text"}
          name={"extremities"}
          uppercase={true}
          defaultValue={"NAD"}
        />

        <CustomInputField
          label={"Psychiatry"}
          type={"text"}
          name={"psychiatry"}
          uppercase={true}
          defaultValue={"NAD"}
        />

        <CustomInputField
          label={"Symptoms"}
          type={"text"}
          name={"symptoms"}
          uppercase={true}
          defaultValue={"ABSENT"}
        />

        <CustomInputField
          label={"Chest X-Ray"}
          type={"text"}
          name={"chestXRay"}
          uppercase={true}
          defaultValue={"CALCIFICATION"}
        />

        <CustomInputField
          label={"ECG"}
          type={"text"}
          name={"ecg"}
          uppercase={true}
          defaultValue={""}
        />
      </div>
    </div>
  );
};

export default MedicalInformation;
