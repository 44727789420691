import moment from "moment";
import React from "react";
//import Barcode from "react-jsbarcode";
import Barcode from "react-barcode";

const HeadInformation = ({ logo, data }) => {
  return (
    <>
      <div className=" mx-auto pt-4 px-4 lg:px-10 text-center flex items-center justify-center">
        <div className="text-right">
          <img className="max-h-20 m-auto" src={logo} alt="logo" />
        </div>
        <div>
          <h1 className="font-Jacques text-xl font-extrabold text-pink-500">
            ADHUNIK DIAGNOSTIC & MEDICAL CENTRE LTD
          </h1>
          <h4 className="uppercase font-semibold text-sm">
            81/3, D.I.T ext road (2nd floor) (zinat tower goli), motijheel
            dhaka, Bangladesh
          </h4>
          <h4 className="capitalize font-semibold text-base">
            mobile: +88 01715 881 868, Email:{" "}
            <span className="lowercase">adhunikdiagnostic252@gmail.com</span>
          </h4>
          <h3 className="uppercase font-bold text-base">
            online report:{" "}
            <a href={`${window.location.origin}/print/${data?._id}`}>
              <span className="lowercase ">{window.location.origin}</span>
              <span className="lowercase ">/print/{data?._id}</span>
            </a>
          </h3>
        </div>
      </div>
      <div className="text-center mt-1">
        <h2 className="text-lg font-bold underline m-auto">
          MEDICAL TEST REPORT
        </h2>
      </div>
      <div className="px-5 flex ">
        <p className="flex w-[52%]">
          <span className="font-bold">Reg No.:</span>{" "}
          <span className="ml-5 flex flex-col ">
            <Barcode
              font="arial"
              fontOptions="bold"
              fontSize={15}
              height={20}
              width={1.5}
              textPosition="top"
              textAlign="left"
              margin={0}
              background="none"
              value={data?.regNo ? data.regNo : "AMC2310431"}
            />
          </span>
        </p>
        <div className="font-bold text-xs  my-1">
          <p>
            REG DATE{" "}
            <span className="ml-7">
              {moment(data?.regDate).format("DD-MM-YYYY")} {data?.regTime}
            </span>
          </p>
          <p>
            REPORT DATE{" "}
            <span className="ml-2">
              {moment(data?.reportDate).format("DD-MM-YYYY")}
            </span>{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default HeadInformation;
