import React from "react";
import HeadInformation from "./headInformation";
import PersonalDetail from "./personalDetail";
import ProfessionalInformation from "./ProfessionalInformation";
import LaboratoryDetails from "./LaboratoryDetails";
import MedicalDetail from "./MedicalDetail";

const Fullpage = ({ logo, data }) => {
  return (
    <div className="inline-block md:flex items-center justify-center">
      <div className="dark:bg-[#292929] text-black dark:text-white w-[#816px] h-[#1056]">
        <HeadInformation logo={logo} data={data} />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5 p-5">
          <PersonalDetail data={data} />
          <ProfessionalInformation data={data} />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5 p-5">
          <MedicalDetail data={data} />
          <LaboratoryDetails data={data} />
        </div>

        <div className="italic font-bold mx-5">
          <div className="flex justify-between ">
            <h3 className="py-2">
              His / Her Status{" "}
              <span className="border px-5 border-black dark:border-gray-400 font-bold uppercase text-xl">
                {data?.status}
              </span>
            </h3>

            <h3 className="py-2">
              Final Checked By:.........................................
            </h3>
          </div>
          <h3 className="py-2">
            REMARKS: <span className="ml-5 ">{data?.remarks}</span>
          </h3>
        </div>

        <div className="container mx-auto pt-6 px-4 lg:px-10 text-center"></div>
      </div>
    </div>
  );
};

export default Fullpage;
