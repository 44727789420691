import moment from "moment";
import React from "react";

const ProfessionalInformation = ({ data }) => {
  return (
    <div className="font-bold flex">
      <div className="grid grid-cols-2  text-[12px] uppercase w-[60%] ">
        <div className="w-[80%] pl-1 border-black dark:border-gray-400 border">
          PROFESSION
        </div>
        <div className="w-[120%] ml-[-23px] pl-1 border-black dark:border-gray-400 border border-l-0 ">
          {data?.profession}
        </div>
        <div className="w-[80%] pl-1 border-black dark:border-gray-400 border-b  border-x">
          COUNTRY
        </div>
        <div className="w-[120%] ml-[-23px] pl-1 border-black dark:border-gray-400 border-b border-r">
          {data?.country}
        </div>

        <div className="w-[80%] pl-1 border-black dark:border-gray-400 border-b border-x">
          {" "}
          BIRTH DATE
        </div>
        <div className="w-[120%] ml-[-23px] pl-1 border-black dark:border-gray-400 border-b border-r">
          {moment(data?.birthDate).format("DD-MM-YYYY")}
        </div>
        <div className="w-[80%] pl-1 border-black dark:border-gray-400 border-b  border-x">
          RELIGION
        </div>
        <div className="w-[120%] ml-[-23px] pl-1 border-black dark:border-gray-400 border-b border-r">
          {data?.religion}
        </div>
        <div className="w-[80%] pl-1 border-black dark:border-gray-400 border-b  border-x">
          ISSUE PLACE
        </div>
        <div className="w-[120%] ml-[-23px] pl-1 border-black dark:border-gray-400 border-b border-r">
          {data?.issuePlace}
        </div>
        <div className="w-[80%] pl-1 border-black dark:border-gray-400 border-b  border-x">
          GENDER
        </div>
        <div className="w-[120%] ml-[-23px] pl-1 border-black dark:border-gray-400 border-b border-r">
          {data?.gender}
        </div>
        <div className="w-[80%] pl-1 border-black dark:border-gray-400 border-b  border-x">
          HEIGHT (CM)
        </div>
        <div className="w-[120%] ml-[-23px] pl-1 border-black dark:border-gray-400 border-b border-r">
          {data?.height} CM
        </div>
        <div className="w-[80%] pl-1 border-black dark:border-gray-400 border-b  border-x">
          WEIGHT (KG)
        </div>
        <div className="w-[120%] ml-[-23px] pl-1 border-black dark:border-gray-400 border-b border-r ">
          {data?.weight} KG
        </div>
      </div>
      <div className=" border-black dark:border-gray-400 border ml-2 w-[40%] h-[180px] ">
        <img className=" w-[100%] h-[100%]" src={data?.photo} alt="User Img" />
      </div>
    </div>
  );
};

export default ProfessionalInformation;
