import moment from "moment";
import React from "react";
import CustomInputField from "../customInputField";
import { sectionGridCX } from "./styledClass";

const MedicalInformation = ({ uniqueEmployee }) => {
  return (
    <div>
      <div className={sectionGridCX}>
        <CustomInputField
          uppercase={true}
          label={"Left Eye Visual Acuity"}
          type={"text"}
          name={"leftEyeVisualAcuity"}
          defaultValue={
            uniqueEmployee?.leftEyeVisualAcuity
              ? uniqueEmployee?.leftEyeVisualAcuity
              : "Not Found"
          }
        />
        <CustomInputField
          uppercase={true}
          label={"Right Eye Visual Acuity"}
          type={"text"}
          name={"rightEyeVisualAcuity"}
          defaultValue={
            uniqueEmployee?.rightEyeVisualAcuity
              ? uniqueEmployee?.rightEyeVisualAcuity
              : "Not Found"
          }
        />
        <CustomInputField
          uppercase={true}
          label={"Left Ear"}
          type={"text"}
          name={"leftEar"}
          defaultValue={
            uniqueEmployee?.leftEar ? uniqueEmployee?.leftEar : "Not Found"
          }
        />

        <CustomInputField
          uppercase={true}
          label={"Right Ear"}
          type={"text"}
          name={"rightEar"}
          defaultValue={
            uniqueEmployee?.rightEar ? uniqueEmployee?.rightEar : "Not Found"
          }
        />

        <CustomInputField
          uppercase={true}
          label={"B.P"}
          type={"text"}
          name={"bp"}
          defaultValue={uniqueEmployee?.bp ? uniqueEmployee?.bp : "Not Found"}
        />

        <CustomInputField
          uppercase={true}
          label={"Heart"}
          type={"text"}
          name={"heart"}
          defaultValue={
            uniqueEmployee?.heart ? uniqueEmployee?.heart : "Not Found"
          }
        />

        <CustomInputField
          uppercase={true}
          label={"Lungs"}
          type={"text"}
          name={"lungs"}
          defaultValue={
            uniqueEmployee?.lungs ? uniqueEmployee?.lungs : "Not Found"
          }
        />

        <CustomInputField
          uppercase={true}
          label={"Gastrointestinal Abdomen"}
          type={"text"}
          name={"gastrointestinalAbdomen"}
          defaultValue={
            uniqueEmployee?.gastrointestinalAbdomen
              ? uniqueEmployee?.gastrointestinalAbdomen
              : "Not Found"
          }
        />

        <CustomInputField
          uppercase={true}
          label={"Hernia"}
          type={"text"}
          name={"hernia"}
          defaultValue={
            uniqueEmployee?.hernia ? uniqueEmployee?.hernia : "Not Found"
          }
        />

        <CustomInputField
          uppercase={true}
          label={"Varicoseveins"}
          type={"text"}
          name={"varicoseveins"}
          defaultValue={
            uniqueEmployee?.varicoseveins
              ? uniqueEmployee?.varicoseveins
              : "Not Found"
          }
        />

        <CustomInputField
          uppercase={true}
          label={"Deformities"}
          type={"text"}
          name={"deformities"}
          defaultValue={
            uniqueEmployee?.deformities
              ? uniqueEmployee?.deformities
              : "Not Found"
          }
        />

        <CustomInputField
          uppercase={true}
          label={"Skin"}
          type={"text"}
          name={"skin"}
          defaultValue={
            uniqueEmployee?.skin ? uniqueEmployee?.skin : "Not Found"
          }
        />

        <CustomInputField
          uppercase={true}
          label={"Cns"}
          type={"text"}
          name={"cns"}
          defaultValue={uniqueEmployee?.cns ? uniqueEmployee?.cns : "Not Found"}
        />

        <CustomInputField
          uppercase={true}
          label={"Extremities"}
          type={"text"}
          name={"extremities"}
          defaultValue={
            uniqueEmployee?.extremities
              ? uniqueEmployee?.extremities
              : "Not Found"
          }
        />

        <CustomInputField
          uppercase={true}
          label={"Psychiatry"}
          type={"text"}
          name={"psychiatry"}
          defaultValue={
            uniqueEmployee?.psychiatry
              ? uniqueEmployee?.psychiatry
              : "Not Found"
          }
        />

        <CustomInputField
          uppercase={true}
          label={"Symptoms"}
          type={"text"}
          name={"symptoms"}
          defaultValue={
            uniqueEmployee?.symptoms ? uniqueEmployee?.symptoms : "Not Found"
          }
        />

        <CustomInputField
          uppercase={true}
          label={"Chest X-Ray"}
          type={"text"}
          name={"chestXRay"}
          defaultValue={
            uniqueEmployee?.chestXRay ? uniqueEmployee?.chestXRay : "Not Found"
          }
        />

        <CustomInputField
          uppercase={true}
          label={"ECG"}
          type={"text"}
          name={"ecg"}
          defaultValue={uniqueEmployee?.ecg ? uniqueEmployee?.ecg : "Not Found"}
        />
      </div>
    </div>
  );
};

export default MedicalInformation;
