import React, { useEffect, useState, useRef } from "react";
import Footer from "../../component/shared/footer";
import NavBar from "../../component/shared/navbar";
import PrintFullpage from "./PrintFullpage";
import Loading from "../../component/shared/loading/Loading";
import useFetch from "../../hooks/useFetch";
import useSession from "../../hooks/useSession";
import { useParams } from "react-router-dom";
import logo from "../../assets/logo.png";
import "./customStyle.css";
import { useReactToPrint } from "react-to-print";

class PrintableComponent extends React.Component {
  render() {
    const { data, logo } = this.props;

    return (
      <div>
        <PrintFullpage logo={logo} data={data} />
      </div>
    );
  }
}

const IndividualPerson = () => {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true"
  );

  const { id } = useParams();
  const [data, , employeeLoading] = useFetch("form", id);
  const [user, userLoading] = useSession();

  console.log(data);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (employeeLoading || userLoading) {
    return <Loading />;
  }

  return (
    <main>
      <NavBar
        user={user ? user : "client"}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
      />
      <div className="w-[#100%] mx-auto">
        <div>
          <div className="flex flex-row-reverse">
            <button
              className="bg-gray-500 text-white p-5"
              onClick={handlePrint}
            >
              Print File
              <i className="ml-2 fa-solid fa-print"></i>
            </button>
          </div>
          <PrintableComponent
            ref={componentRef}
            data={data}
            logo={logo}
            className="printcontent"
          />
        </div>
      </div>
      {/* <PrintFullpage logo={logo} data={data} /> */}

      {/* <Footer darkTheme={darkMode} /> */}
    </main>
  );
};

export default IndividualPerson;
