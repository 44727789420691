import moment from "moment";
import React from "react";
import CustomInputField from "../customInputField";
import { sectionGridCX } from "./styledClass";

const ReportInformation = ({ uniqueEmployee }) => {
  const { regNo, regDate, regTime, reportDate } = uniqueEmployee;

  return (
    <div className={sectionGridCX}>
      <CustomInputField
        label={"Reg No."}
        type={"text"}
        name={"regNo"}
        defaultValue={regNo}
        required={true}
      />

      <CustomInputField
        label={"REG DATE"}
        type={"date"}
        name={"regDate"}
        defaultValue={regDate}
        uppercase={true}
      />

      <CustomInputField
        label={"REG TIME"}
        type={"text"}
        name={"regTime"}
        defaultValue={regTime}
        uppercase={true}
      />

      <CustomInputField
        label={"REPORT DATE"}
        type={"date"}
        name={"reportDate"}
        defaultValue={reportDate}
        uppercase={true}
      />
    </div>
  );
};

export default ReportInformation;
