import React from "react";
import {
  serialThStyle,
  tableActionHeadStyle,
  tableHeadStyle,
} from "./styledClass";

const TableHead = () => {
  return (
    <thead className={tableHeadStyle}>
      <tr className="uppercase">
        <th className={serialThStyle}>serial No</th>
        <th>Name</th>
        <th>Reg No</th>
        <th>Status</th>

        {/* <th>Status</th> */}
        <th>Age</th>
        <th>Agency</th>
        <th className={tableActionHeadStyle}>Action</th>
      </tr>
    </thead>
  );
};

export default TableHead;
