import moment from "moment/moment";
import React from "react";
import { userRoleImage } from "../../utility/Constant";
import {
  cellImageBodyStyle,
  imageDivStyle,
  imageStyle,
  nameStyle,
  locationStyle,
  statusDivStyle,
  actionDivStyle,
  editButtonStyle,
  deleteButtonStyle,
  statusStyle,
  tableCellStyle,
} from "./styledClass";

const TableElementAdmin = ({
  serial,
  item,
  even,
  handleDeleteID,
  handleUpdateUserId,
}) => {
  const { id, createdAt, email, name, role, status, primary } = item;

  return (
    <tr className={tableCellStyle(even)}>
      <th>{serial >= 10 ? serial : "0" + serial}</th>
      <td>
        <div className={cellImageBodyStyle}>
          <div className={imageDivStyle}>
            <div className={imageStyle}>
              <img src={userRoleImage(role)} alt={name + " photo"} />
            </div>
          </div>
          <div>
            <div className={nameStyle}>{name}</div>
            <div className={locationStyle}>Role: {role}</div>
          </div>
        </div>
      </td>
      <td>{email.split("@")[0]}</td>
      <td>{moment(createdAt).format("DD/MM/YYYY")}</td>
      <td>
        <div className={statusDivStyle}>
          <div className={statusStyle(status)} />
        </div>
      </td>
      <th>
        <div className={actionDivStyle}>
          {primary === "false" && (
            <>
              <label
                htmlFor={"updateUser"}
                onClick={handleUpdateUserId}
                className={editButtonStyle}
              >
                <i id={id} className="fa-solid fa-pen" />
              </label>
              <label
                htmlFor="delete-modal"
                onClick={handleDeleteID}
                className={deleteButtonStyle}
              >
                <i id={id} className="fa-solid fa-trash" />
              </label>
            </>
          )}
        </div>
      </th>
    </tr>
  );
};

export default TableElementAdmin;
