import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Loading from "../component/shared/loading/Loading";

import useSession from "../hooks/useSession";

function RequireAuth({ children }) {
  const [user, loading] = useSession();
  const location = useLocation();

  if (loading) {
    return <Loading />;
  }

  if (!user) {
    return <Navigate to={"/login"} state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth;
