import moment from "moment";
import React from "react";
import CustomInputField from "../customInputField";
import { sectionGridCX } from "./styledClass";

const LaboratoryInformation = ({ uniqueEmployee }) => {
  return (
    <div>
      <div className={sectionGridCX}>
        <CustomInputField
          uppercase={true}
          label={"Sugar"}
          type={"text"}
          name={"sugar"}
          defaultValue={uniqueEmployee?.sugar}
        />

        <CustomInputField
          uppercase={true}
          label={"Albumin"}
          type={"text"}
          name={"albumin"}
          defaultValue={uniqueEmployee?.albumin}
        />

        <CustomInputField
          uppercase={true}
          label={"Pregnancy Test"}
          type={"text"}
          name={"pregnancyTest"}
          defaultValue={uniqueEmployee?.pregnancyTest}
        />
        <CustomInputField
          uppercase={true}
          label={"Bilharziasis"}
          type={"text"}
          name={"bilharziasis"}
          defaultValue={uniqueEmployee?.bilharziasis}
        />

        <CustomInputField
          uppercase={true}
          label={"Others"}
          type={"text"}
          name={"others"}
          defaultValue={uniqueEmployee?.others}
        />

        <CustomInputField
          uppercase={true}
          label={"Culture"}
          type={"text"}
          name={"culture"}
          defaultValue={uniqueEmployee?.culture}
        />

        <CustomInputField
          uppercase={true}
          label={"Malaria"}
          type={"text"}
          name={"malaria"}
          defaultValue={uniqueEmployee?.malaria}
        />

        <CustomInputField
          uppercase={true}
          label={"Microfilaria"}
          type={"text"}
          name={"microfilaria"}
          defaultValue={uniqueEmployee?.microfilaria}
        />

        <CustomInputField
          uppercase={true}
          label={"Blood Group"}
          type={"text"}
          name={"bloodGroup"}
          defaultValue={uniqueEmployee?.bloodGroup}
        />

        <CustomInputField
          uppercase={true}
          label={"Haemoglobin"}
          type={"text"}
          name={"haemoglobin"}
          defaultValue={uniqueEmployee?.haemoglobin}
        />

        <CustomInputField
          uppercase={true}
          label={"ESR"}
          type={"text"}
          name={"esr"}
          defaultValue={uniqueEmployee?.esr}
        />

        <CustomInputField
          uppercase={true}
          label={"R.B.S"}
          type={"text"}
          name={"rbs"}
          defaultValue={uniqueEmployee?.rbs}
        />

        <CustomInputField
          uppercase={true}
          label={"Creatinine"}
          type={"text"}
          name={"creatinine"}
          defaultValue={uniqueEmployee?.creatinine}
        />

        <CustomInputField
          uppercase={true}
          label={"T.Bil"}
          type={"text"}
          name={"tBil"}
          defaultValue={uniqueEmployee?.tBil}
        />

        <CustomInputField
          uppercase={true}
          label={"SGPT"}
          type={"text"}
          name={"sgpt"}
          defaultValue={uniqueEmployee?.sgpt}
        />
        <CustomInputField
          uppercase={true}
          label={"SGOT"}
          type={"text"}
          name={"sgot"}
          defaultValue={uniqueEmployee?.sgot}
        />

        <CustomInputField
          uppercase={true}
          label={"ALP"}
          type={"text"}
          name={"alp"}
          defaultValue={uniqueEmployee?.alp}
        />

        <CustomInputField
          uppercase={true}
          label={"Urea"}
          type={"text"}
          name={"urea"}
          defaultValue={uniqueEmployee?.urea}
        />

        <CustomInputField
          uppercase={true}
          label={"HIV I & HIV II"}
          type={"text"}
          name={"hiv"}
          defaultValue={uniqueEmployee?.hiv}
        />

        <CustomInputField
          uppercase={true}
          label={"HBsAg"}
          type={"text"}
          name={"hbsag"}
          defaultValue={uniqueEmployee?.hbsag}
        />

        <CustomInputField
          uppercase={true}
          label={"Anti HCV"}
          type={"text"}
          name={"antiHcv"}
          defaultValue={uniqueEmployee?.antiHcv}
        />
        <CustomInputField
          uppercase={true}
          label={"TPHA (IF VDRL POSITIVE)"}
          type={"text"}
          name={"tpha"}
          defaultValue={uniqueEmployee?.tpha}
        />

        <CustomInputField
          uppercase={true}
          label={"VDRL"}
          type={"text"}
          name={"vdrl"}
          defaultValue={uniqueEmployee?.vdrl}
        />

        {/* last section */}
        <CustomInputField
          uppercase={true}
          label={"Status"}
          type={"text"}
          name={"status"}
          defaultValue={uniqueEmployee?.status}
        />

        <CustomInputField
          uppercase={true}
          spanFull={true}
          paragraph={true}
          label={"Remarks"}
          type={"text"}
          name={"remarks"}
          defaultValue={uniqueEmployee?.remarks}
        />
      </div>
    </div>
  );
};

export default LaboratoryInformation;
