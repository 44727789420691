import React, { useEffect, useState } from "react";
import CustomAlert from "../customeAlert";
import UpdateEmployee from "../formModalUpdate";

import { tableBodyStyle, tableHeadStyle } from "./stateStyledClass";
import useFetch from "../../hooks/useFetch";
import { tableCellStyle } from "./stateStyledClass";
//import LoadingSmall from "../shared/loading/LoadingSmall";

const StateTable = ({
  handleUpdateEmployee,
  updateEmployeeID,
  adminRole,
  userStatus,
}) => {
  const [employee, , employeeLoading] = useFetch("form");
  //filter total employed data
  const filterByItem = (val) => {
    if (val === "Submitted") {
      return employee.filter((data) => data?.passportStat === val).length;
    } else {
      return employee.filter((data) => data?.employment === val).length;
    }
  };

  return (
    <table className={tableBodyStyle}>
      <thead className={tableHeadStyle}>
        <tr>
          <th className="rounded-tl-xl">Name</th>
          <th className="rounded-tr-xl">Value</th>
        </tr>
      </thead>
      <tbody>
        <tr className={tableCellStyle(false)}>
          <td>Total Data</td>

          <td>{employee?.length}</td>
        </tr>
        <tr className={tableCellStyle(true)}>
          <td>Total Employed</td>

          <td>{filterByItem("Employed")}</td>
        </tr>
        <tr className={tableCellStyle(false)}>
          <td>On Process</td>

          <td>{filterByItem("On Process")}</td>
        </tr>
        <tr className={tableCellStyle(true)}>
          <td>Waiting List</td>

          <td>{filterByItem("Waiting List")}</td>
        </tr>
        <tr className={tableCellStyle(false)}>
          <td>Rejected</td>

          <td>{filterByItem("Rejected")}</td>
        </tr>
        <tr className={tableCellStyle(true)}>
          <td>Cancelled</td>

          <td>{filterByItem("Cancelled")}</td>
        </tr>
        <tr className={tableCellStyle(false)}>
          <td>Passport Submitted</td>

          <td>{filterByItem("Submitted")}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default StateTable;
