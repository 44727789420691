import moment from "moment";
import React from "react";

const LaboratoryDetails = ({ data }) => {
  return (
    <div className="font-semibold ">
      <div className="grid grid-cols-2 ">
        <div className="pl-2 border-black dark:border-gray-400 border col-span-2 text-center">
          LABORATORY INVESTIGATION
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border text-[120px]">
          TYPE OF LAB INVESTIGATION
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          RESULTS
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border col-span-2 text-center">
          URINE R/E
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border">
          SUGAR
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.sugar}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          ALBUMIN
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.albumin}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          BILHARZIASIS (IF ENDEMIC)
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.bilharziasis}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          PREGNANCY TEST
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.pregnancyTest}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          OTHERS
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.others}
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border col-span-2 text-center">
          STOOL R/E (If required)
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border">
          HELMINTHES
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.helminthes}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          GIARDIA
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.giardia}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          BILHARZIASIS (IF ENDEMIC)
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.bilharziasis}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          CULTURE
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.culture}
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border col-span-2 text-center">
          BLOOD-CBC & GROUPING
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border">
          MALARIA
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.malaria}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          MICROFILARIA
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          {data?.microfilaria}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          BLOOD GROUP
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.bloodGroup}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          HAEMOGLOBIN
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.haemoglobin}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">ESR</div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.esr}
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border col-span-2 text-center">
          BLOOD-BIOCHEMISTRY
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          R.B.S
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.rbs}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          CREATININE
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.creatinine}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          T.BIL.
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.tBil}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          SGPT
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.sgpt}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          SGOT
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.sgot}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">ALP</div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.alp}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          UREA
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.urea}
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border col-span-2 text-center">
          BLOOD-ELISA & SEROLOGY
        </div>

        <div className="pl-2 border-black dark:border-gray-400 border">
          HIV I & HIV II
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.hiv}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          HBsAg
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.hbsag}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          Anti HCV
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.antiHcv}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          TPHA (IF VDRL POSITIVE)
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.tpha}
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border">
          VDRL
        </div>
        <div className="pl-2 border-black dark:border-gray-400 border ">
          {data?.weight}
        </div>
      </div>
    </div>
  );
};

export default LaboratoryDetails;
