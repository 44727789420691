import React from "react";

const PersonalDetail = ({ data }) => {
  return (
    <div className="font-bold ">
      <div className="grid grid-cols-3 text-[12px]">
        <div className="pl-1  border-black dark:border-gray-400 border">
          NAME
        </div>
        <div className="pl-1 py-1 border-black dark:border-gray-400 border col-span-2">
          {data?.name}
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border">
          PASSPORT NO
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border">
          {data?.passportNo}
        </div>
        <div className=" border-black dark:border-gray-400 border m-2 row-span-6">
          <img
            className="border-black dark:border-gray-400 w-[100%] max-h-28 object-fill m-auto"
            src={data?.fingerPrint}
            alt="Finger print"
          />
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border">
          MERITAL STATUS
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border">
          {data?.maritalStatus}
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border">AGE</div>
        <div className="pl-1 border-black dark:border-gray-400 border">
          {data?.age}
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border">
          ISSUE DATE
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border">
          {data?.issueDate}
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border">
          NATIONALITY
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border">
          {data?.nationality}
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border">
          VISA NO.
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border">
          {data?.visaNo}
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border">
          AGENCY
        </div>
        <div className="pl-1 border-black dark:border-gray-400 border col-span-2">
          {data?.agency}
        </div>
      </div>
    </div>
  );
};

export default PersonalDetail;
