import React from "react";
import StateTable from "../../component/stateTableComponent/StateTable";
import CategoryStateTable from "../../component/stateTableComponent/CategoryStateTable";
import {
  homeContainer,
  pageContainer,
  tableContainer,
} from "../homePage/styledClass";
import NavBar from "../../component/shared/navbar";
import { useState } from "react";
import useFetch from "../../hooks/useFetch";
import useSession from "../../hooks/useSession";
import Loading from "../../component/shared/loading/Loading";
import Error from "../../component/shared/error/error";
import Footer from "../../component/shared/footer";

const Statistics = () => {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true"
  );

  const [users, , usersLoading] = useFetch("user/list");
  const [user, userLoading] = useSession();

  if (usersLoading || userLoading) {
    return <Loading />;
  }
  return (
    <div className={pageContainer}>
      <NavBar user={user} darkMode={darkMode} setDarkMode={setDarkMode} />
      {user?.role === "admin" || user?.role === "moderator" ? (
        <>
          <div className={homeContainer}>
            <h1 className="mb-5 text-center text-2xl font-bold">
              Data Statistics
            </h1>
            <StateTable />
            {/*   </div>
          <div className={homeContainer}> */}
            <h1 className="mt-10 mb-5 text-center text-2xl font-bold">
              Category Statistics
            </h1>
            <CategoryStateTable />
          </div>
        </>
      ) : (
        <Error />
      )}
      <Footer />
    </div>
  );
};

export default Statistics;
