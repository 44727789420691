import moment from "moment";
import React from "react";
import CustomInputField from "../customInputField";
import { sectionGridCX } from "./styledClass";

const PersonalInformation = ({ uniqueEmployee }) => {
  const {
    name,
    passportNo,
    age,
    issueDate,
    agency,
    nationality,
    maritalStatus,
    visaNo,
  } = uniqueEmployee;

  return (
    <div className={sectionGridCX}>
      <CustomInputField
        label={"Name"}
        type={"text"}
        name={"name"}
        defaultValue={name}
        uppercase={true}
      />

      <CustomInputField
        label={"Passport Number"}
        type={"text"}
        name={"passportNo"}
        defaultValue={passportNo}
        uppercase={true}
      />

      <CustomInputField
        label={"Marital Status"}
        type={"text"}
        name={"maritalStatus"}
        select={true}
        defaultValue={maritalStatus}
        options={[
          ["UNMARRIED", "UNMARRIED"],
          ["MARRIED", "MARRIED"],
          ["DIVORCED", "DIVORCED"],
          ["SEPARATED", "SEPARATED"],
          ["WIDOWED", "WIDOWED"],
        ]}
        uppercase={true}
      />

      <CustomInputField
        label={"Age"}
        type={"number"}
        name={"age"}
        defaultValue={age}
        uppercase={true}
      />

      <CustomInputField
        label={"Issue Date"}
        type={"date"}
        name={"issueDate"}
        defaultValue={issueDate}
        uppercase={true}
      />

      <CustomInputField
        label={"Nationality"}
        type={"text"}
        name={"nationality"}
        defaultValue={nationality}
        uppercase={true}
      />

      <CustomInputField
        label={"Visa No"}
        type={"text"}
        name={"visaNo"}
        defaultValue={visaNo}
        uppercase={true}
      />

      <CustomInputField
        label={"Agency"}
        type={"text"}
        name={"agency"}
        defaultValue={agency}
        uppercase={true}
      />

      {/* <CustomInputField
        label={"Address"}
        type={"text"}
        name={"address"}
        spanFull={true}
        paragraph={true}
        defaultValue={address}
        required={true}
      /> */}
    </div>
  );
};

export default PersonalInformation;
