import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { updateData } from "../../hooks/update";
import { getToken } from "../../utility/Constant";
import MultipleFileInput from "../multipleFileInput/multipleFileInput";

import DeleteModal from "./deleteModal";
import PersonalInformation from "./personalInformation";
import {
  categorySectionCX,
  categoryTitleCX,
  formBodyCX,
  formTitleCX,
  modalBoxCX,
  modalCloseButtonCX,
  modalCX,
  modalToggleCX,
  imageUploadDiv,
  imageUploadBox,
  file,
  layer,
  iconsStyle,
  profileImageCX,
  uploadLogoText,
  fileNameCX,
  submitButtonCX,
  submitButton,
} from "./styledClass";
import WorkInformation from "./workInformation";
import useFetch from "../../hooks/useFetch";
import DeleteModalPopup from "./deleteModalPopup";
import ShareWithCustomSelect from "../customInputField/ShareWithCustomSelect";
import MedicalInformation from "./MedicalInformation";
import LaboratoryInformation from "./LaboratoryInformation";
import ReportInformation from "./ReportInformation";

const UpdateEmployee = ({ uniqueEmployee }) => {
  const { _id, photo, fingerPrint, xrayImg, media, files } = uniqueEmployee[0];
  const currentShare = uniqueEmployee[0]?.shareWith;

  console.log(uniqueEmployee[0], currentShare);
  let [isOpen, setIsOpen] = useState(false);
  let [isSharedOpen, setIsSharedOpen] = useState(false);
  let [shareRemoveIdx, setShareRemoveIdx] = useState(null);

  const [manageMedia, setManageMedia] = useState(media);
  const [manageFiles, setManageFiles] = useState(files);

  const formRef = useRef();
  const [profileImage, setProfileImage] = useState(photo);
  const [fingerImg, setFingerImg] = useState(fingerPrint);
  const [exRayImg, setExRayImg] = useState(xrayImg);
  const [fileName, setFileName] = useState("");

  //share with state
  const [shareWith, setShareWith] = useState([]);
  const [shareWithName, setShareWithName] = useState([]);

  //current share data update
  useEffect(() => {
    if (currentShare?.length) {
      if (currentShare?.length > 1) {
        let tempId = [];
        let temp = [];
        currentShare.forEach((element) => {
          temp.push(element[1]);
          tempId.push(element[0]);
        });
        setShareWith(...shareWithName, tempId);
        setShareWithName(...shareWithName, temp);
      } else {
        console.log(currentShare[0][1]);
        setShareWith([...shareWith, currentShare[0][0]]);
        setShareWithName([...shareWithName, currentShare[0][1]]);
      }
    }
  }, [currentShare]);

  const deleteMediaButton = (event, data) => {
    // manageMedia.splice(event.target.id, 1);
    setManageMedia((oldMedia) => oldMedia.filter((item) => item !== data));
  };
  const deleteFileButton = (event, data) => {
    const fileName = data.split("file/")[1];
    setManageFiles((oldFiles) => oldFiles.filter((item) => item !== data));
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/file/${fileName}`,
      headers: { Authorization: "Bearer " + getToken() },
    }).catch(function (error) {
      //handle error
      console.log(error);
    });
  };

  const handleBulkMediasAndFiles = () => {
    setProfileImage("");
    setFingerImg("");
  };

  const handleProfileImage = (event) => {
    const size = event.target.files[0].size;
    if (size < 5000000) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("upload_preset", "ml_default");
      axios
        .post(
          "https://api.cloudinary.com/v1_1/dvpqaydlv/image/upload",
          formData
        )
        .then((response) => setProfileImage(response.data.url));
      setFileName(event.target.files[0].name);
    } else {
      toast.error("Max image limit 5MB!", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleFingerImage = (event) => {
    const size = event.target.files[0].size;
    if (size < 5000000) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("upload_preset", "ml_default");
      axios
        .post(
          "https://api.cloudinary.com/v1_1/dvpqaydlv/image/upload",
          formData
        )
        .then((response) => setFingerImg(response.data.url));
      setFileName(event.target.files[0].name);
    } else {
      toast.error("Max image limit 5MB!", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleExRayImage = (event) => {
    const size = event.target.files[0].size;
    if (size < 5000000) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("upload_preset", "ml_default");
      axios
        .post(
          "https://api.cloudinary.com/v1_1/dvpqaydlv/image/upload",
          formData
        )
        .then((response) => setExRayImg(response.data.url));
      setFileName(event.target.files[0].name);
    } else {
      toast.error("Max image limit 5MB!", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const testArray = [];
    const singleObject = {};
    const employmentArray = [];
    const employmentSingleObject = {};

    const data = {
      dynamicId: event.target.dynamicId?.value,
      regNo: event.target.regNo?.value,
      regDate: event.target.regDate?.value,
      regTime: event.target.regTime?.value,
      reportDate: event.target.reportDate?.value,
      name: event.target.name?.value,
      passportNo: event.target.passportNo?.value,
      maritalStatus: event.target.maritalStatus?.value?.split(",")[0],
      age: event.target.age?.value,
      dob: event.target.dob?.value,
      issueDate: event.target.issueDate?.value,
      nationality: event.target.nationality?.value,
      visaNo: event.target.visaNo?.value,
      agency: event.target.agency?.value,
      profession: event.target.profession?.value,
      country: event.target.country?.value,
      birthDate: event.target.birthDate?.value,
      religion: event.target.religion?.value,
      issuePlace: event.target.issuePlace?.value,
      gender: event.target.gender?.value.split(",")[0],
      height: event.target.height?.value,
      weight: event.target.weight?.value,

      //medical part
      leftEyeVisualAcuity: event.target.leftEyeVisualAcuity?.value,
      rightEyeVisualAcuity: event.target.rightEyeVisualAcuity?.value,
      leftEar: event.target.leftEar?.value,
      rightEar: event.target.rightEar?.value,
      bp: event.target.bp?.value,
      heart: event.target.heart?.value,
      lungs: event.target.lungs?.value,
      gastrointestinalAbdomen: event.target.gastrointestinalAbdomen?.value,
      hernia: event.target.hernia?.value,
      agentName: event.target.agentName?.value,
      varicoseveins: event.target.varicoseveins?.value,
      deformities: event.target.deformities?.value,
      skin: event.target.skin?.value,
      cns: event.target.cns?.value,
      extremities: event.target.extremities?.value,
      psychiatry: event.target.psychiatry?.value,
      symptoms: event.target.symptoms?.value,
      chestXRay: event.target.chestXRay?.value,
      ecg: event.target.ecg?.value,

      //laboratory part
      sugar: event.target.sugar?.value,
      albumin: event.target.albumin?.value,
      pregnancyTest: event.target.pregnancyTest?.value,
      others: event.target.others?.value,
      helminthes: event.target.helminthes?.value,
      giardia: event.target.giardia?.value,
      bilharziasis: event.target.bilharziasis?.value,
      culture: event.target.culture?.value,
      malaria: event.target.malaria?.value,
      microfilaria: event.target.microfilaria?.value,
      bloodGroup: event.target.bloodGroup?.value,
      haemoglobin: event.target.haemoglobin?.value,
      esr: event.target.esr?.value,
      rbs: event.target.rbs?.value,
      creatinine: event.target.creatinine?.value,
      tBil: event.target.tBil?.value,
      sgpt: event.target.sgpt?.value,
      sgot: event.target.sgot?.value,
      alp: event.target.alp?.value,
      urea: event.target.urea?.value,
      hiv: event.target.hiv?.value,
      hbsag: event.target.hbsag?.value,
      antiHcv: event.target.antiHcv?.value,
      tpha: event.target.tpha?.value,
      vdrl: event.target.vdrl?.value,
      status: event.target.status?.value,
      remarks: event.target.remarks?.value,

      fingerPrint: fingerImg,
      photo: profileImage ? profileImage : photo,
      xrayImg: exRayImg,
    };

    console.log(data, "form post", _id, manageMedia);
    updateData({
      endPoint: `form/${_id}`,
      data: data,
      method: "PUT",
      modalCloseID: "updateEmployee",
      formReset: formRef.current.reset(),
      bulkReset: handleBulkMediasAndFiles,
    });
    // handleBulkMediasAndFiles();
  };

  const handleResetForm = () => {
    setProfileImage("");
    setFingerImg("");
    formRef.current.reset();
    handleBulkMediasAndFiles();
    window.location.reload();
  };
  //share with function
  const [shareUser, setShareUser] = useState([["0", "Remove Share"]]);

  //fetch user data
  const [users] = useFetch("user/list");

  //filter and update state
  useEffect(() => {
    let temp = [];
    let clients = users?.filter(function (person) {
      return person.role === "client";
    });
    for (let data of clients) {
      temp.push([data.id, data?.email?.split("@")[0]]);
    }
    setShareUser([["0", "Remove Share"], ...temp]);
  }, [users]);

  // for removing all share
  function handleAllShareRemove() {
    setShareWith([]);
    setShareWithName([]);
  }

  const handleShareWith = (event) => {
    const inputVal = event.target.value.split(",")[0];
    const inputName = event.target.value.split(",")[1];
    if (inputName === "Remove Share") {
      if (shareWithName?.length > 0) {
        setIsSharedOpen(true);
      } else {
        toast.warning(`Not shared with anyone`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else if (inputName === undefined) {
      console.log("-__-");
    } else {
      if (shareWith.includes(inputVal)) {
        toast.warning(`Already shared with ${inputName}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setShareWith((prevShareWith) => [...prevShareWith, inputVal]);
        setShareWithName((prevShareWithName) => [
          ...prevShareWithName,
          inputName,
        ]);
      }
    }
  };

  // Handle Share Remove
  const handleShareRemove = () => {
    const updatedShareWith = [...shareWith];
    updatedShareWith.splice(shareRemoveIdx, 1);
    setShareWith(updatedShareWith);

    const updatedShareWithName = [...shareWithName];
    updatedShareWithName?.splice(shareRemoveIdx, 1);
    setShareWithName(updatedShareWithName);
    closeModal();
  };

  // Confirmation Modal
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function deleteModalOpener(e, index) {
    e.preventDefault();
    setShareRemoveIdx(index);
    openModal();
  }

  return (
    <div>
      <input type="checkbox" id="updateEmployee" className={modalToggleCX} />
      <div className={modalCX}>
        <div className={modalBoxCX}>
          <label
            onClick={handleResetForm}
            htmlFor="updateEmployee"
            className={modalCloseButtonCX}
          >
            ✕
          </label>
          <form
            id="updateEmployee-form"
            ref={formRef}
            onSubmit={handleSubmit}
            className={formBodyCX}
          >
            <h2 className={formTitleCX}>Update Patients</h2>

            <div className="flex-row md:flex items-center justify-between gap-4 mb-10">
              {/* image part */}
              <div className={profileImageCX}>
                <div className={imageUploadDiv}>
                  <div
                    className={imageUploadBox}
                    style={{
                      backgroundImage: `url(${
                        profileImage ? profileImage : uniqueEmployee[0].photo
                      })`,
                    }}
                  >
                    <input
                      type="file"
                      name="photo"
                      className={file}
                      onChange={handleProfileImage}
                      accept="image/*"
                    />
                    <div
                      className={layer(
                        profileImage ? profileImage : uniqueEmployee[0].photo
                      )}
                    >
                      <div className={iconsStyle}>
                        <img
                          src="https://mez.ink/mezink-web/_next/static/images/invoice/imageLogo.png"
                          alt="uploadImageThumbnail"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className={uploadLogoText}>Upload Image</h2>
                  <h2 className={fileNameCX}>
                    {fileName ? fileName : "No file chosen"}
                  </h2>
                </div>
              </div>

              {/* fingerprint part */}
              <div className={profileImageCX}>
                <div className={imageUploadDiv}>
                  <div
                    className={imageUploadBox}
                    style={{
                      backgroundImage: `url(${
                        fingerImg ? fingerImg : uniqueEmployee[0].fingerPrint
                      })`,
                    }}
                  >
                    <input
                      type="file"
                      name="fingerPrint"
                      className={file}
                      onChange={handleFingerImage}
                      accept="image/*"
                    />
                    <div
                      className={layer(
                        profileImage
                          ? profileImage
                          : uniqueEmployee[0].fingerPrint
                      )}
                    >
                      <div className={iconsStyle}>
                        <img
                          src="https://mez.ink/mezink-web/_next/static/images/invoice/imageLogo.png"
                          alt="uploadImageThumbnail"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className={uploadLogoText}>Upload Fingerprint</h2>
                  <h2 className={fileNameCX}>
                    {fileName ? fileName : "No file chosen"}
                  </h2>
                </div>
              </div>

              {/* x-ray part */}

              <div className={profileImageCX}>
                <div className={imageUploadDiv}>
                  <div
                    className={imageUploadBox}
                    style={{
                      backgroundImage: `url(${
                        exRayImg ? exRayImg : uniqueEmployee[0].xrayImg
                      })`,
                    }}
                  >
                    <input
                      type="file"
                      name="xrayImg"
                      className={file}
                      onChange={handleExRayImage}
                      accept="image/*"
                    />
                    <div
                      className={layer(
                        exRayImg ? exRayImg : uniqueEmployee[0].xrayImg
                      )}
                    >
                      <div className={iconsStyle}>
                        <img
                          src="https://mez.ink/mezink-web/_next/static/images/invoice/imageLogo.png"
                          alt="uploadImageThumbnail"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className={uploadLogoText}>X-RAY Report</h2>
                  <h2 className={fileNameCX}>
                    {fileName ? fileName : "No file chosen"}
                  </h2>
                </div>
              </div>
            </div>

            {/* Modal for share remove */}
            <DeleteModalPopup
              isOpen={isOpen}
              closeModal={closeModal}
              handleDelete={handleShareRemove}
            />
            {/* Modal for all share remove */}
            <DeleteModalPopup
              isOpen={isSharedOpen}
              closeModal={() => setIsSharedOpen(false)}
              handleDelete={handleAllShareRemove}
            />
            {/* -------------------------------{shareWith close}------------------------------- */}

            <hr className="mt-5 mb-4 border-gray-300 border-[1.5px]" />

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Report Details:</h2>
              <ReportInformation uniqueEmployee={uniqueEmployee[0]} />
            </div>
            <hr className="mt-5 mb-4 border-gray-300 border-[1.5px]" />

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Personal Details:</h2>
              <PersonalInformation uniqueEmployee={uniqueEmployee[0]} />
            </div>
            <hr className="mt-5 mb-4 border-gray-300 border-[1.5px]" />

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Professional Details:</h2>
              <WorkInformation uniqueEmployee={uniqueEmployee[0]} />
            </div>
            <hr className="mt-5 mb-4 border-gray-300 border-[1.5px]" />

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Medical Examination Details:</h2>
              <MedicalInformation uniqueEmployee={uniqueEmployee[0]} />
            </div>
            <hr className="mt-5 mb-4 border-gray-300 border-[1.5px]" />

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>
                Laboratory Information Details:
              </h2>
              <LaboratoryInformation uniqueEmployee={uniqueEmployee[0]} />
            </div>
            <hr className="mt-5 mb-4 border-gray-300 border-[1.5px]" />

            <div className={submitButtonCX}>
              <button type="submit" className={submitButton}>
                <span htmlFor={"A"}>Save</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      {manageMedia?.map((item, index) => (
        <DeleteModal
          key={index}
          deleteId={index}
          id={`delete-media-modal${index}`}
          handleDelete={(e) => deleteMediaButton(e, item)}
        />
      ))}
      {manageFiles?.map((item, index) => (
        <DeleteModal
          key={index}
          deleteId={index}
          id={`delete-file-modal${index}`}
          handleDelete={(e) => deleteFileButton(e, item)}
        />
      ))}
    </div>
  );
};

export default UpdateEmployee;
