import React, { useEffect, useState } from "react";
import CustomAlert from "../customeAlert";
import UpdateEmployee from "../formModalUpdate";

import { tableBodyStyle, tableHeadStyle } from "./stateStyledClass";
import useFetch from "../../hooks/useFetch";
import { tableCellStyle } from "./stateStyledClass";
//import LoadingSmall from "../shared/loading/LoadingSmall";

const CategoryStateTable = ({
  handleUpdateEmployee,
  updateEmployeeID,
  adminRole,
  userStatus,
}) => {
  const [employee, employeeLoading] = useFetch("form");

  let tempData = [];

  //filter total employed data

  if (employee.length) {
    employee.forEach((element) => {
      if (!tempData.includes(element.workCategory)) {
        tempData.push(element.workCategory);
      }
    });
  }

  const countCategory = (catName) => {
    return employee.filter((data) => data?.workCategory === catName).length;
  };

  return (
    <table className={`${tableBodyStyle} mb-5`}>
      <thead className={tableHeadStyle}>
        <tr>
          <th className="rounded-tl-xl">Category</th>
          <th className="rounded-tr-xl">Value</th>
        </tr>
      </thead>
      <tbody>
        {tempData.map((cat, index) => (
          <tr key={index} className={tableCellStyle(index % 2 === 1)}>
            <td>{cat}</td>
            <td>{countCategory(cat)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CategoryStateTable;
