import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import {
  actionBoxCX,
  iconBodyCX,
  iconBoxCX,
  iconCX,
  inputToggleCX,
  modalCX,
  noButtonCX,
  subTitleCX,
  titleCX,
  yesButtonCX,
} from "../../component/customeAlert/styledClass";

export default function DeleteModalPopup({ isOpen, closeModal, handleDelete }) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[9999999]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900 bg-opacity-30" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}
                >
                  <h1 className={iconBoxCX}>
                    <i className={iconCX}></i>
                  </h1>
                  <h3 className={titleCX}>Are you sure?</h3>
                  <p className={subTitleCX}>
                    You won't be able to revert this!
                  </p>
                  <div className={actionBoxCX}>
                    <button onClick={handleDelete} className={yesButtonCX}>
                      Yes
                    </button>
                    <button onClick={closeModal} className={noButtonCX}>
                      No
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
