import React, { useState } from "react";
import Footer from "../../component/shared/footer";
import Loading from "../../component/shared/loading/Loading";
import NavBar from "../../component/shared/navbar";
import useFetch from "../../hooks/useFetch";
import useSession from "../../hooks/useSession";
import DocStructure from "./DocStructure";

const DocPage = () => {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true"
  );

  const [docData, , docDataLoading] = useFetch("user/doc");
  const [user, userLoading] = useSession();

  if (docDataLoading || userLoading) {
    return <Loading />;
  }

  return (
    <div className="dark:bg-[#292929]">
      <NavBar user={user} darkMode={darkMode} setDarkMode={setDarkMode} />
      <DocStructure data={docData[0]?.docData} user={user} />
      <Footer darkTheme={darkMode} />
    </div>
  );
};

export default DocPage;
