import React from "react";
import CustomInputField from "../customInputField";
import { sectionGridCX } from "./styledClass";

const PersonalInformation = () => {
  return (
    <div className={sectionGridCX}>
      {/*  top left part */}
      <CustomInputField
        label={"Name"}
        type={"text"}
        name={"name"}
        uppercase={true}
      />

      <CustomInputField
        label={"Passport Number"}
        type={"text"}
        name={"passportNo"}
        uppercase={true}
      />

      <CustomInputField
        label={"Marital Status"}
        type={"text"}
        name={"maritalStatus"}
        select={true}
        options={[
          ["UNMARRIED", "UNMARRIED"],
          ["MARRIED", "MARRIED"],
          ["DIVORCED", "DIVORCED"],
          ["SEPARATED", "SEPARATED"],
          ["WIDOWED", "WIDOWED"],
        ]}
        uppercase={true}
      />

      <CustomInputField
        label={"Age"}
        type={"number"}
        name={"age"}
        uppercase={true}
      />

      <CustomInputField
        label={"Issue Date"}
        type={"date"}
        name={"issueDate"}
        uppercase={true}
      />

      <CustomInputField
        label={"Nationality"}
        type={"text"}
        name={"nationality"}
        uppercase={true}
      />

      <CustomInputField
        label={"Visa No"}
        type={"text"}
        name={"visaNo"}
        uppercase={true}
      />

      <CustomInputField
        label={"Agency"}
        type={"text"}
        name={"agency"}
        uppercase={true}
      />
    </div>
  );
};

export default PersonalInformation;
