import axios from "axios";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { updateData } from "../../hooks/update";
import { getToken } from "../../utility/Constant";

import CustomInputField from "../customInputField";
import MultipleFileInput from "../multipleFileInput/multipleFileInput";

import PersonalInformation from "./personalInformation";
import {
  categorySectionCX,
  categoryTitleCX,
  formBodyCX,
  formTitleCX,
  modalBoxCX,
  modalCloseButtonCX,
  modalCX,
  modalToggleCX,
  imageUploadDiv,
  imageUploadBox,
  file,
  layer,
  iconsStyle,
  profileImageCX,
  uploadLogoText,
  fileNameCX,
  submitButtonCX,
  submitButton,
} from "./styledClass";
import WorkInformation from "./workInformation";
import useFetch from "../../hooks/useFetch";
import { useEffect } from "react";
import DeleteModalPopup from "../formModalUpdate/deleteModalPopup";
import ShareWithCustomSelect from "../customInputField/ShareWithCustomSelect";
import MedicalInformation from "./MedicalInformation";
import LaboratoryInformation from "./LaboratoryInformation";
import ReportInformation from "./ReportInformation";

const AddUpdateEmployee = () => {
  const formRef = useRef();
  const [profileImage, setProfileImage] = useState("");
  const [fingerImg, setFingerImg] = useState("");
  const [exRayImg, setExRayImg] = useState("");
  const [profileImageName, setProfileImageName] = useState("");
  const [fingerImgName, setFingerImgName] = useState("");
  const [exRayImgName, setExRayImgName] = useState("");
  let [isOpen, setIsOpen] = useState(false);
  let [isSharedOpen, setIsSharedOpen] = useState(false);
  let [shareRemoveIdx, setShareRemoveIdx] = useState(null);

  const [mediaFiles, setMediaFiles] = useState([]);
  const [bulkFiles, setBulkFiles] = useState([]);

  const [clearance, setClearance] = useState("No");

  //share with state
  const [shareWith, setShareWith] = useState([]);
  const [shareWithName, setShareWithName] = useState([]);

  const handleBulkMediasAndFiles = () => {
    setMediaFiles(0);
    setBulkFiles();
    setProfileImage("");
    setFingerImg("");
    setExRayImg("");
  };

  const handleProfileImage = (event) => {
    const size = event.target.files[0].size;
    if (size < 5000000) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("upload_preset", "ml_default");
      axios
        .post(
          "https://api.cloudinary.com/v1_1/dvpqaydlv/image/upload",
          formData
        )
        .then((response) => setProfileImage(response.data.url));
      setProfileImageName(event.target.files[0].name);
    } else {
      toast.error("Max image limit 5MB!", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleFingerImage = (event) => {
    const size = event.target.files[0].size;
    if (size < 5000000) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("upload_preset", "ml_default");
      axios
        .post(
          "https://api.cloudinary.com/v1_1/dvpqaydlv/image/upload",
          formData
        )
        .then((response) => setFingerImg(response.data.url));
      setFingerImgName(event.target.files[0].name);
    } else {
      toast.error("Max image limit 5MB!", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleExRayImage = (event) => {
    const size = event.target.files[0].size;
    if (size < 5000000) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("upload_preset", "ml_default");
      axios
        .post(
          "https://api.cloudinary.com/v1_1/dvpqaydlv/image/upload",
          formData
        )
        .then((response) => setExRayImg(response.data.url));
      setExRayImgName(event.target.files[0].name);
    } else {
      toast.error("Max image limit 5MB!", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // bulk image file handel start
  const handleImageFile = (file) => {
    const size = file.size;
    if (size < 5000000) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "ml_default");
      axios
        .post(
          "https://api.cloudinary.com/v1_1/dvpqaydlv/image/upload",
          formData
        )
        .then((response) => {
          //mediaFiles.push(response.data.url);
          setMediaFiles([...mediaFiles, response.data.url]);
        });
    } else {
      toast.error("Max image limit 5MB!", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const handleMultipleImage = (event) => {
    const files = event.target.files;
    const fileLimit = files.length;

    if (fileLimit <= 5) {
      for (let file of files) {
        handleImageFile(file);
      }
    } else {
      toast.error("Max Image Limit 5!", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // bulk image file handel End

  const handleMultipleFile = async (event) => {
    const files = event.target.files;
    const formData = new FormData();
    if (files) {
      [...files]?.map((file) => formData.append("files", file));

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/file/upload`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + getToken(),
        },
      })
        .then(function (response) {
          //handle success
          setBulkFiles(response.data.data);
        })
        .catch(function (error) {
          //handle error
          console.log(error);
        });
    }
    // for (let file of files) {
    //   bulkFiles.push(file.name);
    // }
  };
  const nestedInputHandle = (event) => {
    let inputVal = event?.target;
    if (inputVal?.expCompanyName?.length) {
    }
  };

  // bulk file handel End

  const handleSubmit = (event) => {
    event.preventDefault();
    const testArray = [];
    const singleObject = {};
    const employmentArray = [];
    const employmentSingleObject = {};
    //share with data process
    let shareDataWith = [];
    for (let i = 0; i < shareWith.length; i++) {
      shareDataWith.push([shareWith[i], shareWithName[i]]);
    }
    const data = {
      dynamicId: event.target.dynamicId?.value,
      regNo: event.target.regNo?.value,
      regTime: event.target.regTime?.value,
      regDate: event.target.regDate?.value,
      reportDate: event.target.reportDate?.value,
      name: event.target.name?.value,
      passportNo: event.target.passportNo?.value,
      maritalStatus: event.target.maritalStatus?.value?.split(",")[0],
      age: event.target.age?.value,
      dob: event.target.dob?.value,
      issueDate: event.target.issueDate?.value,
      nationality: event.target.nationality?.value,
      visaNo: event.target.visaNo?.value,
      agency: event.target.agency?.value,
      profession: event.target.profession?.value,
      country: event.target.country?.value,
      birthDate: event.target.birthDate?.value,
      religion: event.target.religion?.value,
      issuePlace: event.target.issuePlace?.value,
      gender: event.target.gender?.value.split(",")[0],
      height: event.target.height?.value,
      weight: event.target.weight?.value,

      //medical part
      leftEyeVisualAcuity: event.target.leftEyeVisualAcuity?.value
        ? event.target.leftEyeVisualAcuity?.value
        : "NAD",
      rightEyeVisualAcuity: event.target.rightEyeVisualAcuity?.value
        ? event.target.rightEyeVisualAcuity?.value
        : "NAD",
      leftEar: event.target.leftEar?.value
        ? event.target.leftEar?.value
        : "NAD",
      rightEar: event.target.rightEar?.value
        ? event.target.rightEar?.value
        : "NAD",
      bp: event.target.bp?.value ? event.target.bp?.value : "NAD",
      heart: event.target.heart?.value ? event.target.heart?.value : "NAD",
      lungs: event.target.lungs?.value ? event.target.lungs?.value : "NAD",
      gastrointestinalAbdomen: event.target.gastrointestinalAbdomen?.value
        ? event.target.gastrointestinalAbdomen?.value
        : "NAD",
      hernia: event.target.hernia?.value ? event.target.hernia?.value : "NAD",
      agentName: event.target.agentName?.value
        ? event.target.agentName?.value
        : "NAD",
      varicoseveins: event.target.varicoseveins?.value
        ? event.target.varicoseveins?.value
        : "NAD",
      deformities: event.target.deformities?.value
        ? event.target.deformities?.value
        : "NAD",
      skin: event.target.skin?.value ? event.target.skin?.value : "NAD",

      cns: event.target.cns?.value ? event.target.cns?.value : "NAD",
      extremities: event.target.extremities?.value
        ? event.target.extremities?.value
        : "NAD",
      psychiatry: event.target.psychiatry?.value
        ? event.target.psychiatry?.value
        : "NAD",
      symptoms: event.target.symptoms?.value
        ? event.target.symptoms?.value
        : "NAD",
      chestXRay: event.target.chestXRay?.value
        ? event.target.chestXRay?.value
        : "NAD",
      ecg: event.target.ecg?.value ? event.target.ecg?.value : "NAD",

      //laboratory part
      sugar: event.target.sugar?.value ? event.target.sugar?.value : "NIL",
      albumin: event.target.albumin?.value
        ? event.target.albumin?.value
        : "NIL",
      pregnancyTest: event.target.pregnancyTest?.value
        ? event.target.pregnancyTest?.value
        : "",
      others: event.target.others?.value ? event.target.others?.value : "",
      bilharziasis: event.target.bilharziasis?.value
        ? event.target.bilharziasis?.value
        : "NOT FOUND",
      helminthes: event.target.helminthes?.value
        ? event.target.helminthes?.value
        : "NOT FOUND",
      giardia: event.target.giardia?.value
        ? event.target.giardia?.value
        : "NOT FOUND",
      culture: event.target.culture?.value
        ? event.target.culture?.value
        : "NOT FOUND",
      malaria: event.target.malaria?.value
        ? event.target.malaria?.value
        : "NOT FOUND",
      microfilaria: event.target.microfilaria?.value
        ? event.target.microfilaria?.value
        : "NOT FOUND",
      bloodGroup: event.target.bloodGroup?.value,
      haemoglobin: event.target.haemoglobin?.value + " gm/dL",
      esr: event.target.esr?.value + " mm/hr",
      rbs: event.target.rbs?.value + " mg/dL",
      creatinine: event.target.creatinine?.value + " mg/dL",
      tBil: event.target.tBil?.value + " mg/dL",
      sgpt: event.target.sgpt?.value + " U/L",
      sgot: event.target.sgot?.value + " U/L",
      alp: event.target.alp?.value + " U/L",
      urea: event.target.urea?.value + " mg/dL",
      hiv: event.target.hiv?.value,
      hbsag: event.target.hbsag?.value,
      antiHcv: event.target.antiHcv?.value,
      tpha: event.target.tpha?.value,
      vdrl: event.target.vdrl?.value,
      status: event.target.status?.value?.split(",")[0],
      remarks: event.target.remarks?.value,

      fingerPrint: fingerImg,
      photo: profileImage,
      xrayImg: exRayImg,
    };
    //console.log(data, "form post");
    updateData({
      endPoint: `form`,
      data: data,
      method: "POST",
      modalCloseID: "addEmployee",
      bulkReset: handleBulkMediasAndFiles,
    });
    // handleBulkMediasAndFiles();
  };

  //share with function
  const [shareUser, setShareUser] = useState([["0", "Remove Share"]]);

  //fetch user data
  const [users] = useFetch("user/list");

  //filter and update state
  useEffect(() => {
    let temp = [];
    let clients = users?.filter(function (person) {
      return person.role === "client";
    });
    for (let data of clients) {
      temp.push([data.id, data?.email?.split("@")[0]]);
    }
    setShareUser([["0", "Remove Share"], ...temp]);
  }, [users]);

  // for removing all share
  function handleAllShareRemove() {
    setShareWith([]);
    setShareWithName([]);
    setIsSharedOpen(false);
  }

  const handleShareWith = (event) => {
    const inputVal = event.target.value.split(",")[0];
    const inputName = event.target.value.split(",")[1];
    if (inputName === "Remove Share") {
      if (shareWithName?.length > 0) {
        setIsSharedOpen(true);
      } else {
        toast.warning(`Not shared with anyone`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else if (inputName === undefined) {
      console.log("lol");
    } else {
      if (shareWith.includes(inputVal)) {
        toast.warning(`Already shared with ${inputName}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.log("normal");
        setShareWith((prevShareWith) => [...prevShareWith, inputVal]);
        setShareWithName((prevShareWithName) => [
          ...prevShareWithName,
          inputName,
        ]);
      }
    }
  };

  // Handle Share Remove
  const handleShareRemove = () => {
    const updatedShareWith = [...shareWith];
    updatedShareWith.splice(shareRemoveIdx, 1);
    setShareWith(updatedShareWith);

    const updatedShareWithName = [...shareWithName];
    updatedShareWithName?.splice(shareRemoveIdx, 1);
    setShareWithName(updatedShareWithName);
    closeModal();
  };

  // Confirmation Modal
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function deleteModalOpener(e, index) {
    e.preventDefault();
    setShareRemoveIdx(index);
    openModal();
  }

  return (
    <div>
      <input type="checkbox" id="addEmployee" className={modalToggleCX} />
      <div className={modalCX}>
        <div className={modalBoxCX}>
          <label
            htmlFor="addEmployee"
            onClick={() => {
              formRef.current.reset();
              handleBulkMediasAndFiles();
              setClearance("No");
            }}
            className={modalCloseButtonCX}
          >
            ✕
          </label>
          <form ref={formRef} onSubmit={handleSubmit} className={formBodyCX}>
            <h2 className={formTitleCX}>Add Patients</h2>
            <div className="flex-row md:flex items-center justify-between gap-4 mb-10">
              <div className={profileImageCX}>
                <div className={imageUploadDiv}>
                  <div
                    className={imageUploadBox}
                    style={{
                      backgroundImage: `url(${profileImage})`,
                    }}
                  >
                    <input
                      type="file"
                      name="photo"
                      className={file}
                      onChange={handleProfileImage}
                      formEncType="multipart/form-data"
                      accept="image/*"
                    />
                    <div className={layer(profileImage)}>
                      <div className={iconsStyle}>
                        <img
                          src="https://mez.ink/mezink-web/_next/static/images/invoice/imageLogo.png"
                          alt="uploadImageThumbnail"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* profile img section */}
                <div>
                  <h2 className={uploadLogoText}>Upload Image</h2>
                  <h2 className={fileNameCX}>
                    {profileImageName ? profileImageName : "No file chosen"}
                  </h2>
                </div>
              </div>

              <div className={profileImageCX}>
                <div className={imageUploadDiv}>
                  <div
                    className={imageUploadBox}
                    style={{
                      backgroundImage: `url(${fingerImg})`,
                    }}
                  >
                    <input
                      type="file"
                      name="fingerPrint"
                      className={file}
                      onChange={handleFingerImage}
                      formEncType="multipart/form-data"
                      accept="image/*"
                    />
                    <div className={layer(fingerImg)}>
                      <div className={iconsStyle}>
                        <img
                          src="https://mez.ink/mezink-web/_next/static/images/invoice/imageLogo.png"
                          alt="uploadImageThumbnail"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Fingerprint img section */}
                <div>
                  <h2 className={uploadLogoText}>Upload Fingerprint</h2>
                  <h2 className={fileNameCX}>
                    {fingerImgName ? fingerImgName : "No file chosen"}
                  </h2>
                </div>
              </div>

              {/* xray img section */}
              <div className={profileImageCX}>
                <div className={imageUploadDiv}>
                  <div
                    className={imageUploadBox}
                    style={{
                      backgroundImage: `url(${exRayImg})`,
                    }}
                  >
                    <input
                      type="file"
                      name="xrayImg"
                      className={file}
                      onChange={handleExRayImage}
                      formEncType="multipart/form-data"
                      accept="image/*"
                    />
                    <div className={layer(exRayImg)}>
                      <div className={iconsStyle}>
                        <img
                          src="https://mez.ink/mezink-web/_next/static/images/invoice/imageLogo.png"
                          alt="uploadImageThumbnail"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h2 className={uploadLogoText}>Upload X-RAY Report</h2>
                  <h2 className={fileNameCX}>
                    {exRayImgName ? exRayImgName : "No file chosen"}
                  </h2>
                </div>
              </div>
            </div>

            {/* Modal for share remove */}
            <DeleteModalPopup
              isOpen={isOpen}
              closeModal={closeModal}
              handleDelete={handleShareRemove}
            />
            {/* Modal for all share remove */}
            <DeleteModalPopup
              isOpen={isSharedOpen}
              closeModal={() => setIsSharedOpen(false)}
              handleDelete={handleAllShareRemove}
            />

            <hr className="mt-5 mb-4 border-gray-300 border-[1.5px]" />

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Report Details:</h2>
              <ReportInformation />
            </div>
            <hr className="mt-5 mb-4 border-gray-300 border-[1.5px]" />

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Patients Details:</h2>
              <PersonalInformation />
            </div>
            <hr className="mt-5 mb-4 border-gray-300 border-[1.5px]" />

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Professional Details:</h2>
              <WorkInformation
                clearance={clearance}
                setClearance={setClearance}
              />
            </div>

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Medical Examination Details:</h2>
              <MedicalInformation />
            </div>

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Laboratory investigation:</h2>
              <LaboratoryInformation />
            </div>

            <div className={submitButtonCX}>
              <button type="submit" className={submitButton}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUpdateEmployee;
